import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

export function PasswordInput({
  type = 'password',
  label,
  className = 'form-control',
  placeholder,
  name,
  inputRef,
  errors,
}) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="form-group">
      <FormControl className={className} variant="outlined" fullWidth>
        <InputLabel shrink={true} htmlFor="outlined-adornment-password">
          {t(label)}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          notched={true}
          placeholder={t(placeholder)}
          variant="outlined"
          label={label}
          fullWidth
          name={name}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={inputRef}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
      {errors[name] && errors[name].message !== '' && (
        <span className="error-message-ag">{errors[name].message}</span>
      )}
    </div>
  );
}
