import React, { useState, useEffect, useRef } from "react";
import { MessageList, MessageBox, Input, Button } from "react-chat-elements";
import { Card, CardHeader, Avatar, Popover, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Service from "../../services/UserService";
import { setConversation, getUnreadMessages } from "../../actionCreators";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { socket } from "../../services/Socket";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({}));

const ChatInterface = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messagesArrayObject, setMessagesArrayObject] = useState([]);
  const [conversation, setConversations] = useState();
  const [messagesPagination, setMessagesPagination] = useState(2);
  let fetchConversation = useSelector((state) => state.conversation);
  const { user } = useSelector((state) => state);
  const userProfile = (id, name) => {
    return (
      <Link style={{ color: "#000" }} to={"/user-profile/" + id}>
        {name}
      </Link>
    );
  };

  const [value, setValue] = useState("");
  let inputRef = useRef();

  let getConversationMessages = async (id) => {
    const response = await Service.Request(
      `/chat_service/messages/${id}`,
      {},
      "GET"
    );
    setMessages(response.data);
  };

  const deleteChat = async () => {
    await Service.Request(
      `/chat_service/conversation/${conversation.id}`,
      {},
      "DELETE"
    );
    socket.emit("updateChats", { id: user._id });
    dispatch(setConversation({ deleted_conversation: conversation.id }));
    setAnchorEl(null);
    props.reduxUpdated();
  };

  const blockUser = async () => {
    const response = await Service.Request(
      `/chat_service/block/${conversation.userId}`,
      {},
      "POST"
    );
    if (response.success) {
      toast.success(t(response.message));
      socket.emit("userBlocked", { id: user._id });
      if (conversation.userType == "seller") {
        conversation.sellerBlocked = true;
      } else {
        conversation.buyerBlocked = true;
      }
      setConversations(conversation);
      dispatch(setConversation(conversation));
    } else {
      toast.error(t(response.message));
    }

    setAnchorEl(null);
  };
  // const loadMoreMessages = async () => {
  //     const response = await Service.Request(
  //       `/chat_service/messages?page=${messagesPagination}`
  //     );
  //     if (response.data.length > 0) {
  //       setMessagesPagination((messagesPagination) => messagesPagination + 1);
  //       let messagesArray = messages;
  //       response.data.map((messageObject) => messagesArray.push(messageObject));
  //       setMessages(messagesArray);
  //     }
  //   }
  // };

  const unBlockUser = async () => {
    const response = await Service.Request(
      `/chat_service/block/${conversation.userId}`,
      {},
      "DELETE"
    );
    socket.emit("userBlocked", { id: user._id });
    if (response.success) {
      toast.success(t(response.message));
      if (conversation.userType == "seller") {
        conversation.sellerBlocked = false;
      } else {
        conversation.buyerBlocked = false;
      }
      setConversations(conversation);
      dispatch(setConversation(conversation));
    } else {
      toast.error(t(response.message));
    }

    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const sendMessage = async (text) => {
    inputRef.clear();
    // const response = await Service.Request(
    //   `chat_service/message`,
    //   { conversation: conversation.id, content: text },
    //   "POST"
    // );
    socket.emit("sendMessage", {
      content: text,
      id: user._id,
      conversation: conversation.id,
      userId: conversation.userId,
    });

    setValue("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (conversation && conversation.id) {
      getConversationMessages(conversation.id);
      setMessageRead();
    }
  }, [conversation]);

  useEffect(() => {
    socket.on("messageSend", (message) => {
      if (message.success) {
        socket.emit("getConversations", {
          id: user._id,
        });
        if (conversation && conversation.id == message.data[0].conversation) {
          setMessages(message.data);
        }
        updateMessagesCounter();
      } else {
        toast.error(t(message.message));
      }
    });
    return () => {
      socket.off("messageSend");
      socket.off("sendMessage");
    };
  }, [conversation]);

  useEffect(() => {
    setConversations(fetchConversation);
  }, [fetchConversation]);

  const loadMoreMessages = async () => {
    //console.log("scroll");
  };

  const setMessageRead = async () => {
    const response = await Service.Request(
      `chat_service/message/read/${conversation.id}`,
      {},
      "GET"
    );

    if (response.success) {
      updateMessagesCounter();
    }
  };

  const updateMessagesCounter = async () => {
    const unreadData = await Service.Request(
      `chat_service/unread-messages`,
      {},
      "GET"
    );

    dispatch(getUnreadMessages(unreadData.data));
  };

  useEffect(() => {
    let messagesArray = [];
    if (messages.length > 0) {
      messages.map((message) => {
        messagesArray.unshift({
          position: user._id == message.sender ? "right" : "left",
          type: "text",
          text: parse(message.content),
          date: new Date(message.created_at),
        });
        setMessagesArrayObject(messagesArray);
      });
    }
  }, [messages]);
  return (
    <React.Fragment>
      {conversation && conversation.title ? (
        <div className="topchatProfile-main">
          <Card className="topchatProfile">
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  src={conversation.image}
                  className={classes.avatar}
                />
              }
              action={
                <div>
                  <MoreVertIcon
                    title={t("More")}
                    aria-describedby={id}
                    onClick={handleClick}
                    className="blockChat"
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem onClick={deleteChat}>{t("Delete Chat")}</MenuItem>
                    {(conversation.userType == "seller" &&
                      !conversation.sellerBlocked) ||
                    (conversation.userType == "buyer" &&
                      !conversation.buyerBlocked) ? (
                      <MenuItem onClick={blockUser}>{t("Block user")}</MenuItem>
                    ) : (
                      <MenuItem onClick={unBlockUser}>
                        {t("Unblock user")}
                      </MenuItem>
                    )}
                  </Popover>
                </div>
              }
              title={userProfile(conversation.userId, conversation.title)}
              //   subheader="online"
            />
          </Card>

          <MessageList
            className="message-list"
            lockable={true}
            toBottomHeight={"100%"}
            dataSource={messagesArrayObject}
            onScroll={() => loadMoreMessages()}
          />

          <div className="rce-container-input-wrapper">
            {user._id !== conversation?.sellerId && (
              <div className="btn-group">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendMessage("I'm interested");
                  }}
                >
                  {t("I'm interested")}
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendMessage("Hi! i'd like to buy it");
                  }}
                >
                  {t("Hi! i'd like to buy it")}
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendMessage("Is the price negotiable?");
                  }}
                >
                  {t("Is the price negotiable?")}
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendMessage("i'm not Interested");
                  }}
                >
                  {t("i'm not Interested")}
                </a>
              </div>
            )}
            <Input
              placeholder={`${t("Type here")}...`}
              defaultValue={value}
              ref={(el) => (inputRef = el)}
              name="message"
              onChange={(e) => {
                setValue(e.target.value.trim());
              }}
              multiline={true}
              autoHeight={false}
              onKeyPress={(ev) => {
                if (ev.key === "Enter" && value !== "" && value.trim() !== "") {
                  sendMessage(value);
                  // textareaRef.current.setSelectionRange(cursorPosition, cursorPosition)}
                }
                if (ev.target.value.length === 0) {
                  setMessageRead();
                }
              }}
              rightButtons={
                <Button
                  title={t("Send")}
                  color="white"
                  backgroundColor="black"
                  text={t("Send")}
                  onClick={(e) => {
                    if (value != "") {
                      sendMessage(value);
                    }
                  }}
                />
              }
            />
          </div>
        </div>
      ) : (
        <div className="userChatInner">
          <img src="/images/ChatStartConversation.svg" />
        </div>
      )}
    </React.Fragment>
  );
};

export default ChatInterface;
