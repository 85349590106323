import React, { useState, useEffect } from "react";
import ProductsModule from "./ProfileProductModule";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Service from "../../services/UserService";

function ProfileTabSection(props) {
  const { t } = useTranslation();
  const { value } = props;
  const [products, setProducts] = useState([]);
  const profileSection = {
    Selling: "listing_service/products/selling",
    Sold: "listing_service/products/sold",
    Pending: "listing_service/products/pending",
    Disabled: "listing_service/products/disabled",
    Favorites: "listing_service/favorites",
  };

  useEffect(() => {
    getProducts();
  }, [props.value]);

  useEffect(() => {
    if (props.id) {
      setProducts(products.filter((p) => p._id !== props.id));
      props.setId("");
    }
  }, [props.id]);

  async function getProducts() {
    if (profileSection[value]) {
      const response = await Service.Request(profileSection[value], {}, "GET");
      let data = response.success ? response.data : [];
      setProducts(data);
    }
  }

  function updateProducts(id, isFavorite = false) {
    if (isFavorite) {
      setProducts(products.filter((p) => p.product._id !== id));
    } else {
      setProducts(products.filter((p) => p._id !== id));
    }
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  return (
    <>
      <TabPanel value={value} index={value} className="panelProfile">
        <section className="you-will-like featured-products pt-0 profile-products sold-and-selling">
          <div className="container-fluid px-md-2">
            {products && products.length > 0 ? (
              <ProductsModule
                products={products}
                updateProducts={updateProducts}
                favorite={value === "Favorites"}
                {...props}
              />
            ) : (
              <h6
                className="d-flex justify-content-center"
                style={{ backgroundColor: "#F8F8F8", padding: "25px" }}
              >
                {t(`No ${value} Product`)}
              </h6>
            )}
          </div>
        </section>
      </TabPanel>
    </>
  );
}

export default ProfileTabSection;
