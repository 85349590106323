import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import AuthService from "../../services/UserService";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

const ReportProduct = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [itemReport, setItemReport] = useState("");

  const { register, handleSubmit, control, errors } = useForm();
  const onSubmit = async (data) => {
    try {
      const url = "listing_service/products/report";
      const response = await AuthService.Request(
        url,
        {
          product_id: props.product_id,
          reason: data.itemReport,
          comment: data.description,
        },
        "POST"
      );
      if (response.success) {
        toast.success(t(response.message));
        props.handle();
      } else {
        toast.error(t(response.message));
        props.handle();
      }
    } catch (error) {}
  };

  const handleChange = (event) => {
    setItemReport(event.target.value);
  };

  return (
    <Modal
      className="report-this-item-popup"
      show={props.show}
      onHide={props.onHide}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title style={{ lineHeight: "2.5" }}>
          {t("Report this item")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <div className="container-fluid">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl component="fieldset">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={itemReport}
                  name="itemReport"
                  as={
                    <RadioGroup
                      aria-label="itemReport"
                      value={itemReport}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="offensiveContent"
                        control={<Radio color="primary" />}
                        label={t("Offensive Content")}
                      />
                      <FormControlLabel
                        value="fraud"
                        control={<Radio color="primary" />}
                        label={t("Fraud")}
                      />
                      <FormControlLabel
                        value="duplicateProduct"
                        control={<Radio color="primary" />}
                        label={t("Duplicate Product")}
                      />
                      <FormControlLabel
                        value="productSold"
                        control={<Radio color="primary" />}
                        label={t("Product Sold")}
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio color="primary" />}
                        label={t("Other")}
                      />
                      {errors.itemReport ? (
                        <span style={{ color: "#F44336", marginBottom: "5px" }}>
                          {t("Please select an option")}
                        </span>
                      ) : (
                        ""
                      )}
                    </RadioGroup>
                  }
                />
              </FormControl>

              <TextField
                id="exampleFormControlTextarea1"
                label={t("Description")}
                multiline
                rows={2}
                aria-label="maximum height"
                name="description"
                placeholder={t("Description")}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    .toString()
                    .replace(/^\s/g, "");
                }}
                variant="outlined"
                inputRef={register({
                  required: t("Description is required"),
                  minLength: {
                    value: 5,
                    message: t("Description must be 5 characters long"),
                  },
                })}
                color="primary"
                error={errors.description && errors.description.message !== ""}
                helperText={
                  errors.description ? errors.description.message : " "
                }
              />

              <FormControl variant="outlined">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.reportBtn}
                >
                  {t("Submit")}
                </Button>
              </FormControl>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReportProduct;
