import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProductSuccess from "./productSuccess";
import ProductError from "./productError";

function App(props) {
  const history = useHistory();
  const location = useLocation();
  const { slug, productId, category, subCategory, success } = location;
  useEffect(() => {
    if (!slug || !productId || !category || !subCategory) {
      history.push("/profile");
    }
  }, []);
  return (
    <React.Fragment>
      {success ? <ProductSuccess {...props} /> : <ProductError {...props} />}
    </React.Fragment>
  );
}

export default App;
