import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Service from "../../../services/UserService";
import { verifyLocale } from "../../../services/Helpers";
import { findFlagUrlByIso2Code } from "country-flags-svg";

const dropdown = {
  fontSize: "0.9rem",
  marginRight: "0.5rem",
};

const imgStyle = {
  border: "1px solid #ccc",
  width: "28px",
  paddingRight: "3px",
  paddingLeft: "3px",
  textShadowColor: "rgba(0, 0, 0, 0.75)",
  textShadowOffset: { width: -1, height: 1 },
  textShadowRadius: 10,
};
const iconStyle = {
  marginLeft: "6px",
  color: "#3789DA",
};

const getLanguageTranslations = async (userLanguage) => {
  const { data } = await Service.Request(
    `setting_service/listselectedtranslations?locale=${userLanguage}`,
    "",
    "GET"
  );
  localStorage.setItem("translations", JSON.stringify(data));
};

export default function LanguagesDropDown(props) {
  const languages = useSelector((state) => state.languages);
  let chosenLanguage;
  const { t, i18n } = useTranslation();
  const handleSelect = async (e) => {
    const locale = e.target.value;
    localStorage.setItem("defaultLocale", locale);
    const direction = languages.find((l) => l.defaultLocale === locale);
    localStorage.setItem("dir", direction.direction);
    i18n.changeLanguage(locale);
    var fullPath = window.location.pathname;
    fullPath = fullPath.slice(6);
    const pathname = window.location.origin + "/" + locale;
    await getLanguageTranslations(locale);
    window.location = pathname + fullPath;
  };
  const defaultLocale =
    window.location.pathname.split("/")[1] && verifyLocale().length > 0
      ? window.location.pathname.split("/")[1]
      : localStorage.getItem("fallbackLanguage");

  languages.map((language) =>
    defaultLocale == language?.defaultLocale
      ? (chosenLanguage = language?.name)
      : ""
  );
  return (
    <>
      {props.mainComponent && (
        <label>
          <b className="pr-2">{t("Language")}: </b>
        </label>
      )}
      <Select
        title={t(chosenLanguage)}
        id="select"
        style={dropdown}
        name="languageLocale"
        defaultValue={defaultLocale}
        MenuProps={{
          disableScrollLock: true,
        }}
        onChange={handleSelect}
      >
        {languages.map((lang) =>
          lang?.defaultLocale ? (
            <MenuItem
              title={t(lang?.name)}
              key={lang?.defaultLocale}
              value={lang?.defaultLocale}
            >
              {lang?.defaultLocale == "en_US" ? (
                <i
                  className="fa fa-globe mr-2"
                  aria-hidden="true"
                  height="20px"
                  width="20px"
                  style={iconStyle}
                ></i>
              ) : (
                <img
                  src={findFlagUrlByIso2Code(lang?.defaultLocale.substr(3, 4))}
                  style={imgStyle}
                  height="20px"
                  width="20px"
                  className="mr-2"
                />
              )}
              {props.mainComponent ? lang?.name : ""}
            </MenuItem>
          ) : (
            <i
              class="fa fa-globe"
              aria-hidden="true"
              height="20px"
              width="20px"
              style={iconStyle}
            ></i>
          )
        )}
      </Select>
    </>
  );
}
