import React from "react";
import { Modal, Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Service from "../../services/UserService";
import { useTranslation } from "react-i18next";

function GetModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, - ${left}%)`,
    marginLeft: `-200px`,
    marginTop: `-100px`,
  };
}

const useStyles = makeStyles((theme) => ({

}));

export default function LogoutModule(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const logout = async () => {
    props.close();
    await Service.Logout();
    history.push("/logout");
  };
  const closed = () => {
    props.close();
  };
  const [modalStyle] = React.useState(GetModalStyle);
  const body = (
    <div style={modalStyle} className="main-paper paper">
      <h3 id="simple-modal-tiele" className="headingSize">
        {" "}
        {t("Logout")}
      </h3>
      <p id="simple-modal-description">
        {t("Are you sure you want to logout?")}
      </p>
      <Button
        title={t("Yes")}
        variant="contained"
        className="yesBtn primarybg"
        onClick={logout}
      >
        {t("Yes")}
      </Button>
      <Button title={t("No")} variant="contained" onClick={closed}>
        {t("No")}
      </Button>
    </div>
  );
  return (
    <div>
      <Modal
        open={props.open}
        // onClose={props.close}
        aria-labelledby="simple-modal-tiele"
        aria-labelledby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
