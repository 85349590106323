import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction, setUser } from "../../actionCreators";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import MobileLogin from "../Models";
import { useTranslation } from "react-i18next";
import MetaTitle from "../common/MetaTitle";
import { Modal } from "react-bootstrap";
import SocialLogin from "../common/SocialLogin";
import { PasswordInput } from "../common/control/PasswordField";
import LoginPageContent from "../common/content/LoginPageContent";
import LdapLogin from "../common/LdapLogin";
const useStyles = makeStyles((theme) => ({
}));

const { REACT_APP_LDAP_LOGIN } = process.env;

export default function App(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const classes = useStyles();
  const [ldap, setLDAP] = useState(REACT_APP_LDAP_LOGIN == "true");

  const closeMobileModal = () => setShowMobileModal(false);

  const handleDisabled = () => setDisabled((disabled) => !disabled);
  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = async (data) => {
    handleDisabled();

    let response;

    if (ldap) {
      response = await Auth.doUserRequest("user_service/ldaplogin", {
        username: data.email,
        password: data.password,
      });
    } else {
      response = await Auth.doUserRequest("user_service/login", {
        email: data.email,
        password: data.password,
      });
    }

    if (response.success) {
      window.location.reload(false);

      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.token));

      dispatch(loginAction());
      dispatch(setUser(response.data));

      history.push("/");
    } else {
      setTimeout(() => {
        handleDisabled();
      }, 2000);

      toast.error(t(response.message) || "Network error");
    }
  };

  return (
    <>
      <MetaTitle title="Login" />
      <Modal
        show={showMobileModal}
        onHide={closeMobileModal}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <MobileLogin
          loginWithMobile={true}
          showPhoneLogin={showMobileModal}
          close={closeMobileModal}
        />
      </Modal>

      <div className="d-flex justify-content-center align-items-center container login-auth-page">
        <div className="col-md-7 col-xl-5">
          <div className="checkout-info-box" style={{ minHeight: "auto" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LdapLogin
                ldap={ldap}
                reset={reset}
                register={register}
                errors={errors}
                {...props}
              />

              <PasswordInput
                className={clsx(classes.margin, classes.textField)}
                label="Password"
                placeholder="Password"
                name="password"
                errors={errors}
                inputRef={register({
                  required: t("Password is required"),
                })}
              />
              <LoginPageContent
                disabled={disabled}
                ldap={ldap}
                setLDAP={setLDAP}
                {...props}
              />
              <button
                title={t("Login with Mobile")}
                type="button"
                className="btn btn-primary loginClass"
                style={{
                  padding: "13px",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
                disabled={disabled}
                onClick={(e) => {
                  setShowMobileModal(true);
                }}
              >
                <i className="fa fa-mobile" aria-hidden="true"></i>
                {t("Login with Mobile")}
              </button>
              <SocialLogin disabled={disabled} />
              <div className="create-new-account">
                <p>{t("Don’t have an account then")} </p>
                <Link title={t("Signup")} to="/register">
                  {t("Signup")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
