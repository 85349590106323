import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuery, currencyLabel } from "../../services/Helpers";
import Service from "../../services/UserService";
import { addPackage, removePackage } from "../../actionCreators";
import MetaTitle from "../common/MetaTitle";
import { useTranslation } from "react-i18next";

const DiscountPackages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedPackages = useSelector((state) => state.packages);
  const locations = useSelector((state) => state?.locations || {});
  const { settings } = useSelector((state) => ({
    settings: state.settings,
  }));
  let currencySymbol = t(
    currencyLabel(locations?.currencySymbol || settings?.default_currency)
  );
  const [show, setShow] = useState(false);
  const handleShow = () => setShow((show) => !show);
  const [packageListing, setPackageListing] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const { category, subCategory } = getAllQuery(true);
  const handleImageChange = (image) => setImageURL(image);
  const [itemsCount, setItemsCount] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);
  let formatter = new Intl.NumberFormat("en-US", {});

  const decrement = () => setItemsCount((selectedItems) => selectedItems - 1);
  const increment = () => setItemsCount((selectedItems) => selectedItems + 1);

  const addPriceToTotal = (price) =>
    setItemsTotal((itemsTotal) => itemsTotal + price);
  const subtractPriceFromTotal = (price) =>
    setItemsTotal((itemsTotal) => itemsTotal - price);

  const getPackages = async () => {
    if (category && subCategory) {
      const response = await Service.Request(
        `/listing_service/packages?category=${category}&&subCategory=${subCategory}`,
        {},
        "GET"
      );
      if (response.success) {
        setPackageListing(response.data);
      } else {
        history.push("/404");
      }
    } else {
      history.push("/package/options");
    }
  };

  useEffect(() => {
    if (selectedPackages) {
      setItemsCount(selectedPackages.length);
      setItemsTotal(
        selectedPackages.reduce(function (previousValue, currentValue) {
          return previousValue + +currentValue.price * +currentValue.quantity;
        }, 0)
      );
    }
  }, [selectedPackages]);

  useEffect(() => {
    const selectedPackages = localStorage.getItem("packages");
    if (selectedPackages) {
      dispatch(addPackage(JSON.parse(selectedPackages)));
    }
  }, [dispatch]);

  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const PackageListing = () => {
    return packageListing?.map((packageDetail) => (
      <>
        <div className="pack-content-main">
          <div className="pack-content">
            <div className="content-box">
              <div className="section-title">
                <h5 className="mb-0">{packageDetail?.name}</h5>
                <button
                  type="button"
                  className="btn btn-danger d-none"
                  onClick={() => {
                    handleImageChange(packageDetail?.image);
                    handleShow();
                  }}
                >
                  See Examples
                </button>
              </div>
              <div className="text-withicon">
                {packageDetail?.description.split("\n").map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <span>
                        <i className="fa fa-star"></i>
                        {item}
                      </span>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>

            {packageDetail?.packageOptions?.map((option, index) => (
              <>
                {packageDetail?.packages.find(
                  (p) =>
                    p.packageTypeOptions?.packageSelected?.numberOfDays ===
                    option.numberOfDays
                ) && (
                  <div className="check-box-main" key={index}>
                    <div className="section-title ">
                      <h6 className="mb-0">{option?.heading}</h6>
                    </div>
                    <div className="text-withicon mb-2">
                      {option?.description.split("\n").map((item, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            <span>
                              <i className="fa fa-star"></i>
                              {item}
                            </span>
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className={`check-box-list`}>
                      {packageDetail?.packages.map(
                        (singlePackage, index) =>
                          option.numberOfDays ===
                            singlePackage?.packageTypeOptions?.packageSelected
                              ?.numberOfDays && (
                            <div className="box-withcheck" key={index}>
                              <div className="form-inner">
                                <div className="custom-control">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    defaultChecked={selectedPackages.find(
                                      (selectedPackage) =>
                                        singlePackage._id ===
                                        selectedPackage._id
                                    )}
                                    id={singlePackage._id}
                                    value={JSON.stringify(singlePackage)}
                                    onClick={(e) => {
                                      const selectedPackage = JSON.parse(
                                        e.target.value
                                      );
                                      if (e.target.checked) {
                                        increment();
                                        addPriceToTotal(
                                          +selectedPackage?.price
                                        );
                                        dispatch(
                                          addPackage({
                                            ...selectedPackage,
                                            quantity: 1,
                                          })
                                        );
                                      } else {
                                        decrement();
                                        subtractPriceFromTotal(
                                          +selectedPackage?.price
                                        );
                                        dispatch(
                                          removePackage(selectedPackage)
                                        );
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={singlePackage._id}
                                  >
                                    {singlePackage?.name}
                                  </label>
                                </div>
                              </div>
                              <div className="price-content-main">
                                <div className="price">
                                  <span className="discount">
                                    {currencySymbol} {singlePackage?.price}
                                  </span>
                                  <del className="old">
                                    {currencySymbol}{" "}
                                    {singlePackage?.actualPrice}
                                  </del>
                                </div>
                                <div className="discount-icon">
                                  <span>
                                    {singlePackage.fixedDiscount ? "-" : ""}{" "}
                                    {singlePackage?.discount}
                                    {singlePackage.fixedDiscount ? "" : "%"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </>
    ));
  };

  return (
    <React.Fragment>
      <MetaTitle title="Available Packages" />
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="packages-options-main">
                <div className="pack-heading pb-4 px-0">
                  {/* <div className="icon-img">
                  <img alt="images" src="/images/businessMulti.png" />
                </div> */}
                  <div className="section-title">
                    <a className="back" href="#">
                      <i className="fa fa-arrow-left"></i>
                    </a>
                    <h5>Available Packages</h5>
                  </div>
                </div>
                {itemsCount > 0 && (
                  <div className="pack-total-price-main">
                    <div className="pack-total-price">
                      <div className="left">
                        <div className="items">
                          <span>item</span>
                          <span>{itemsCount}</span>
                        </div>
                        <div className="total">
                          <span>Total</span>
                          <span>
                            {currencySymbol}, {formatter.format(itemsTotal)}
                          </span>
                        </div>
                      </div>
                      <div className="right">
                        <div className="pack-view-cart">
                          <Link to="/view/cart">
                            view cart <i className="fa fa-shopping-cart"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {PackageListing()}
                {packageListing?.length === 0 && (
                  <h4 className="d-flex justify-content-center">
                    No Package Available
                  </h4>
                )}

                {itemsCount > 0 && (
                  <div className="pack-total-price-main">
                    <div className="pack-total-price">
                      <div className="left">
                        <div className="items">
                          <span>item</span>
                          <span>{itemsCount}</span>
                        </div>
                        <div className="total">
                          <span>Total</span>
                          <span>
                            {currencySymbol}, {formatter.format(itemsTotal)}
                          </span>
                        </div>
                      </div>
                      <div className="right">
                        <div className="pack-view-cart">
                          <Link to="/view/cart">
                            view cart <i className="fa fa fa-shopping-cart"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal className="discountPackages-example" show={show}>
          <Modal.Header
            className="modal-header border-0 justify-content-end"
            onHide={handleShow}
            closeButton
          ></Modal.Header>
          <Modal.Body>
            <>
              <img alt="Example" src={imageURL} className="img-fluid" />
            </>
          </Modal.Body>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default DiscountPackages;
