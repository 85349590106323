import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CountdownModule from "../Models/CountdownModule";
import OtpInput from "react-otp-input";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function App(props) {
  const { t } = useTranslation();
  //   const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState("");
  const [value, setValue] = useState();
  const [DisableSubmit, setDisableSubmit] = useState(true);
  const handleChange = (otp) => {
    setValue(otp);
    if (otp.length === 6) {
      setDisableSubmit(false);
    } else {
      setError("");
      setDisableSubmit(true);
    }
  };

  const onSubmit = async (data) => {
    const response = await Auth.Request(
      "user_service/verify-email",
      {
        email: props.email,
        code: value,
      },
      "POST"
    );
    if (response.success) {
      await Auth.asyncLocalStorage.setItem(
        "user",
        JSON.stringify(response.data)
      );
      await Auth.asyncLocalStorage.setItem(
        "token",
        JSON.stringify(response.token)
      );
      toast.success(t("Email updated successfully"));
      props.close();
    } else {
      setError("Invalid code");
      setDisableSubmit(true);
      // toast.error("Invalid Code please try again");
    }
  };
  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide} centered>
        <Modal.Header
          closeButton
          onClick={() => props.close}
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>{t("Please verify your email")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-center"></h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OtpInput
              value={value}
              onChange={handleChange}
              numInputs={6}
              isInputNum={true}
              inputStyle="partitioned"
              shouldAutoFocus={true}
              separator={<span>&nbsp;</span>}
            />
            <span className="error-message-ag">{t(error)}</span>
            <button
              type="submit"
              className="btn btn-primary number-submit-ag"
              disabled={DisableSubmit}
              style={{ width: "100%" }}
            >
              {t("Submit")}
              <i className="fa fa-angle-right"></i>
            </button>
            <CountdownModule submitEmail={props.submitEmail} isEmail={false} />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
