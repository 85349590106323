import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import { setFeatureProducts } from "../../actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Login from "./../Models";
import Service from "./../../services/UserService";
import { BannerAd } from "../BannerAd/BannerAd";
import {
  locationLabelTranslations,
  getParsedLocalStorageData,
  standardPricing,
  urduPricing,
} from "../../services/Helpers";
import "moment/locale/ur";

export default function ProductsModule(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let featureProducts = useSelector((state) => state.featureProducts);
  moment.locale();

  const { bannerAds, setting, locations } = useSelector((state) => ({
    bannerAds: state.bannerAd,
    setting: state.settings,
    locations: state.locations,
  }));

  let gridClass = props.public
    ? "col-sm-6 col-md-6 col-xl-4"
    : "col-sm-6 col-md-6 col-xl-3";

  const [products, setProducts] = useState([]);
  let token = getParsedLocalStorageData("token");
  const [showLogin, setShowLogin] = useState(false);
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    appendDots: (dots) => {
      return (
        <MagicSliderDots
          dots={dots}
          numDotsToShow={4}
          dotWidth={20}
          prevNextDotClassName=""
        />
      );
    },
  };
  useEffect(() => {
    setProducts(props.products);
  }, [props.products]);

  const handleClose = () => setShowLogin(false);

  async function likeProduct(id, index) {
    if (products && token) {
      let url = `posting_service/favorites/${id}`;
      await Service.Request(url, "", "GET");
      let allProducts = [...products];
      let product = { ...allProducts[index], is_favorite: true };
      allProducts[index] = product;
      setProducts(allProducts);
    } else {
      setShowLogin(true);
    }
  }

  async function removeLike(id, index) {
    if (products && token) {
      let url = `posting_service/favorites/${id}`;
      await Service.Request(url, {}, "DELETE");
      let allProducts = [...products];
      let product = { ...allProducts[index], is_favorite: false };
      allProducts[index] = product;
      setProducts(allProducts);
    } else {
      setShowLogin(true);
    }
  }

  const _renderAttributes = (product) => {
    if (!product || product.attributes.length == 0) {
      return null;
    }

    let _attributesContent = "";

    if (product.category && product.category.slug) {
      product.attributes.forEach((attr) => {
        const { title, value, unit, show_on_listing, type } = attr;
        let _attrValue = "";
        if (show_on_listing) {
          type && type === "options"
            ? (_attrValue = `${t(value)} ${t(unit)}`)
            : (_attrValue = `${value} ${unit}`);
        }

        if (_attrValue) {
          _attributesContent += `${
            _attributesContent ? " - " : ""
          }${_attrValue}`;
        }
      });
    }

    return <p className="text-muted mb-0">{_attributesContent}</p>;
  };

  const locale = localStorage.getItem("defaultLocale");
  const _renderFeatureProduct = (product, index) => {
    let _innerProduct;
    _innerProduct = (
      <div className="post-inner">
        <figure title={product.title}>
          <Slider {...settings} className="slider">
            {product.images.map((image) => {
              return (
                <div className="slider slider-for01" key={index}>
                  <div>
                    <Link
                      to={{
                        pathname: `/product/${product.slug}`,
                        state: {
                          prevPath: window.location.pathname,
                        },
                      }}
                    >
                      <img
                        src={image}
                        alt={product.name}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              );
            })}
          </Slider>
          <Link
            to={{
              pathname: `/product/${product.slug}`,
              state: {
                prevPath: window.location.pathname,
              },
            }}
          >
            <figcaption>
              <h4>{product.title}</h4>

              <div className="price-tag">
                {product.is_free || product.price == 0
                  ? t("Free")
                  : locale === "ur_PK"
                  ? urduPricing(product.price, t, setting, locations)
                  : standardPricing(product.price, t, setting, locations)}
              </div>
              {_renderAttributes(product)}
              <p className="text-muted mb-0">
                {product && product?.state && (
                  <>
                    {product?.city?.city && product?.locality?.locality ? (
                      <>
                        {locationLabelTranslations(
                          product?.locality,
                          product?.localityTranslation,
                          "locality"
                        )}
                        ,{" "}
                        {locationLabelTranslations(
                          product?.city,
                          product?.cityTranslation,
                          "city"
                        )}{" "}
                        {""}
                      </>
                    ) : (
                      <>
                        {locationLabelTranslations(
                          product?.city,
                          product?.cityTranslation,
                          "city"
                        )}
                      </>
                    )}
                  </>
                )}
              </p>
              <p className="text-muted mb-0 timing-text-product">
                {moment(product.created_at).fromNow()}
              </p>
            </figcaption>
          </Link>

          {product && product.is_favorite ? (
            <div
              title={t("Mark as Unfavorite")}
              className="heart"
              onClick={() => {
                removeLike(product._id, index);
              }}
            >
              <a>
                <i className="fa fa-heart" aria-hidden="true"></i>
              </a>
            </div>
          ) : (
            <div
              title={t("Mark as Favorite")}
              className="heart"
              onClick={(e) => {
                e.preventDefault();
                likeProduct(product._id, index);
              }}
            >
              <a href="#">
                <i className="fa fa-heart-o" aria-hidden="true"></i>
              </a>
            </div>
          )}
          <div className="tags">
            <span className={product.is_featured ? "featured" : ""}>
              {product && product.is_featured ? t("Featured") : null}
            </span>
          </div>
        </figure>
      </div>
    );
    return (
      <React.Fragment>
        <div className={gridClass}>{_innerProduct}</div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Modal
        show={showLogin}
        onHide={handleClose}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Login close={handleClose} />
      </Modal>
      <div className="row no-gutters">
        {!products || products.length === 0
          ? null
          : products.map((product, i) => {
              return (
                <React.Fragment key={product._id}>
                  {_renderFeatureProduct(product, i)}
                  {bannerAds?.length > 0 && (
                    <>
                      {i === 3 ? (
                        <div className="bannerad-listing-page col-sm-12 col-md-12 col-xl-12">
                          <BannerAd location="listingPage" />
                        </div>
                      ) : null}
                    </>
                  )}
                </React.Fragment>
              );
            })}
      </div>
    </React.Fragment>
  );
}
