import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function SecuredRoute(props) {
  const Component = props.component;
  const loggedIn = useSelector((state) => state.loggedIn);
  return (
    <Route
      path={props.path}
      render={(data) =>
        loggedIn ? <Redirect to={{ pathname: "/" }} /> : <Component />
      }
    ></Route>
  );
}
