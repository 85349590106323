import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import { setAddOptions } from "../../actionCreators";

export default function AddOption() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addOptions } = useSelector((state) => state);
  const { locations } = useSelector((state) => ({
    locations: state?.locations?.states || [],
  }));

  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleStatesLocations = (event, value) => {
    if (value?._id) {
      setSelectedLocation(value);
      dispatch(setAddOptions({ ...addOptions, state: value._id }));
    } else {
      setSelectedLocation(null);
      dispatch(setAddOptions({ ...addOptions, state: null }));
    }
  };

  return (
    <>
      <Autocomplete
        options={locations}
        value={selectedLocation}
        getOptionLabel={(locations) => {
          if (locations?.translation?.length > 0) {
            return (
              locations?.translation[0]?.labelTranslation || locations?.state
            );
          }
          return locations?.state;
        }}
        onChange={handleStatesLocations}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Select Location")}
            variant="outlined"
            name="state"
            size="small"
            autoComplete="off"
            value="Pakistan"
          />
        )}
      />
    </>
  );
}
