import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Location from "./location";
import Categories from "./categories";
import { useHistory } from "react-router-dom";
import { setAddOptions, removeAllPackages } from "../../actionCreators";
import MetaTitle from "../common/MetaTitle";

const PackageOptions = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disableButton, setDisableButton] = useState(true);
  const { addOptions } = useSelector((state) => state);

  useEffect(() => {
    dispatch(setAddOptions({ category: "", subCategory: "", state: "" }));
    dispatch(removeAllPackages());
    localStorage.setItem("packages", []);
  }, [dispatch]);

  useEffect(() => {
    if (
      Object.keys(addOptions).length > 2 &&
      Object.keys(addOptions).every(function (k) {
        return addOptions[k];
      })
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [addOptions]);

  const redirectToPackages = () => {
    if (
      Object.keys(addOptions).every(function (k) {
        return addOptions[k];
      })
    ) {
      history.push(
        `/choose/packages?location=${addOptions.state}&category=${addOptions.category}&subCategory=${addOptions.subCategory}`
      );
    }
  };

  return (
    <React.Fragment>
      <MetaTitle title="Select your Package(s)" />
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="add-options-main">
                <div className="section-title pb-0">
                  <h3 className="w-100 text-center">
                    {t("Select your Package(s)")}
                  </h3>
                </div>
                <div className="options MuiPaper-elevation1 MuiPaper-rounded">
                  <Categories />
                  <div className="locate">
                    <Location />
                  </div>
                  <button
                    className="btn btn-primary"
                    disabled={disableButton}
                    onClick={redirectToPackages}
                  >
                    {t("Show Packages")}
                    {/* <i className="fa fa-chevron-right" aria-hidden="true"></i> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PackageOptions;
