import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import MobileModalContent from "../common/MobileModalContent";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
export default function App(props) {
  //   const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { t } = useTranslation();
  const { handleSubmit, errors } = useForm();
  const [value, setValue] = useState();
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (data) => {
    setDisable(true);
    if (value) {
      try {
        const phoneNumber = parsePhoneNumberWithError("+" + value);
        if (!phoneNumber.isValid()) {
          setError(t("Invalid phone number"));
          setDisable(false);
        } else {
          const response = await Auth.doUserRequest(
            "user_service/login-mobile",
            {
              mobile: "+" + value,
            }
          );
          if (response.success) {
            response.codeSend &&
              toast.success(t("Verification code sent successfully"));
            localStorage.setItem("phone", JSON.stringify("+" + value));
            if (response.codeSend) {
              props.showPhone();
            } else {
              props.showPasswordLogin();
            }
          } else {
            setError(response.message);
            setDisable(false);
          }
        }
      } catch (err) {
        setError(t("Invalid phone number"));
        // toast.error("Invalid phone number");
        setDisable(false);
      }
    } else {
      setError(t("Phone number is required"));
      // toast.error("Phone number is required");
      setDisable(false);
    }
  };
  return (
    <React.Fragment>
      {props.showLogin && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.showLogin();
          }}
        >
          <i
            className="fa fa-arrow-left"
            style={{ marginBottom: "20px", fontSize: "20px" }}
          ></i>
        </a>
      )}

      <h5>{t("Enter Your Phone Number")}</h5>
      <MobileModalContent
        submitAction={handleSubmit(onSubmit)}
        disableSubmit={disable}
        handleChange={setValue}
        error={error}
        value={value}
      />
    </React.Fragment>
  );
}
