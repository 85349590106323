import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { prepareFiltersURL, getRouteParams } from "../../services/Helpers";
import "./dropDown.css";

const DropDown = () => {
  const { categories, selectedCategory, settings } = useSelector(
    (state) => state
  );
  const { t } = useTranslation();
  const history = useHistory();
  function slideSubCategories(e) {
    e.target.parentElement.classList.toggle("sub-show");
    e.target.parentElement.children[2].classList.toggle("subCategoryHide");
  }
  const categoryLabelTranslations = (category) => {
    if (category?.translation?.length > 0) {
      return category.translation[0]?.labelTranslation;
    }
    return category?.title;
  };
  function moreLessText(e) {
    e.target.parentElement.parentElement.classList.toggle("exceed");
    if (e.target.textContent === t("More")) {
      e.target.textContent = t("Less");
    } else {
      e.target.textContent = t("More");
    }
  }

  const categoryChanged = (category) => {
    const _cat = category;
    const cat_slug = category?.slug ? category?.slug : "all";
    let _filterUrl = `/category/${cat_slug}${prepareFiltersURL([], true)}`;
    history.push(getRouteParams(_filterUrl, _cat));
    document.getElementById("megamenupopup").style.display = "none";
  };
  const categoryListing = categories.map((category, index) => {
    return (
      <li className="haveChild" key={index}>
        {category.catChilds.length > 0 && (
          <i className="fa fa-caret-down" onClick={slideSubCategories}></i>
        )}
        <a
          title={t(categoryLabelTranslations(category))}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            categoryChanged(category);
          }}
        >
          <span
            style={{
              color:
                (selectedCategory.slug == category.slug ||
                  selectedCategory.parent_category == category.id) &&
                `${settings.color}`,
            }}
          >
            {categoryLabelTranslations(category)}
          </span>
        </a>
        {category.catChilds.length > 0 && (
          <div className="megamenu-sub exceed">
            <ul className="megamenu-sub-list">
              {category.catChilds.map((childCategory, i) => {
                return (
                  <li className={""} key={i}>
                    <a
                      title={categoryLabelTranslations(childCategory)}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        categoryChanged(childCategory);
                      }}
                    >
                      <span
                        style={{
                          color:
                            selectedCategory.slug == childCategory.slug &&
                            `${settings.color}`,
                        }}
                      >
                        {" "}
                        {categoryLabelTranslations(childCategory)}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
            {category.catChilds.length > 5 && (
              <div className="lessmore-main">
                {
                  <a
                    href="#"
                    className="dropdownmore"
                    onClick={(e) => {
                      e.preventDefault();
                      moreLessText(e);
                    }}
                  >
                    {t("More")}
                  </a>
                }
              </div>
            )}
          </div>
        )}
      </li>
    );
  });

  return (
    <React.Fragment>
      <div className="top-header-megamenu">
        <div className="megamenu megamenu-head">
          <div className="selected">
            <span title={t("All Categories")}>
              {selectedCategory === "all"
                ? t("All Categories")
                : categoryLabelTranslations(selectedCategory)}
            </span>
            <div className="icons">
              {selectedCategory !== "all" ? (
                <i
                  className="fa fa-close"
                  onClick={(e) => categoryChanged("all")}
                ></i>
              ) : null}
              <i className="fa fa-caret-down"></i>
            </div>
          </div>
        </div>
        <div className="megamenu pop-up" id="megamenupopup">
          <div className="megamenu-content-tree">
            <ul>{categoryListing}</ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DropDown;
