import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import CountdownModule from "../Models/CountdownModule";
import { useTranslation } from "react-i18next";
export default function VerificationModalContent(props) {
  const {
    backAction,
    submitAction,
    disableSubmit,
    handleChange,
    error,
    value,
  } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <form onSubmit={submitAction}>
        <OtpInput
          value={value}
          onChange={handleChange}
          numInputs={6}
          isInputNum={true}
          inputStyle="partitioned"
          shouldAutoFocus={true}
          separator={<span>&nbsp;</span>}
        />
        <span className="error-message-ag">{t(error)}</span>
        <button
          type="submit"
          className="btn btn-primary number-submit-ag"
          disabled={disableSubmit}
        >
          {t("Submit")}
          <i className="fa fa-angle-right"></i>
        </button>
        <CountdownModule showPhone={backAction} />
      </form>
    </React.Fragment>
  );
}
