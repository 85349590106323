import React, { useEffect, useState } from "react";
import {
  Paper,
  Tab,
  Card,
  CardHeader,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { ChatList } from "react-chat-elements";
import Service from "../../services/UserService";
import { setConversation } from "../../actionCreators";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { socket } from "../../services/Socket";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({}));

const ChatTabs = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState("1");
  const [conversationList, setConversationList] = useState([]);
  const [userAllConversations, setUserAllConversations] = useState([]);
  const [userSelling, setUserSelling] = useState([]);
  const [userSellingList, setUserSellingList] = useState([]);
  const [userBuying, setUserBuying] = useState([]);
  const [userBuyingList, setUserBuyingList] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [blockedUserList, setBlockedUserList] = useState([]);
  const [isActive, setIsActive] = useState(true);
  let fetchConversation = useSelector((state) => state.conversation);
  const { user } = useSelector((state) => state);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleActive = () => setIsActive((isActive) => !isActive);
  if (!socket) {
    window.location.reload(false);
  }

  socket.on("listBlocked", (blockedUsers) => {
    if (blockedUsers.success) {
      setBlockedUsers(blockedUsers.data);
    }
  });

  const unblockButton = (id, name) => {
    return (
      <div>
        <span>{name}</span>
        <button
          className="btn btn-secondary btn-md unblock-btn"
          onClick={() => unBlockUser(id)}
        >
          {t("Unblock")}
        </button>
      </div>
    );
  };
  const unBlockUser = async (id) => {
    const response = await Service.Request(
      `/chat_service/block/${id}`,
      {},
      "DELETE"
    );
    if (response.success) {
      toast.success(t(response.message));
      setBlockedUsers(blockedUsers.filter((user) => user._id !== id));
    } else {
      toast.error(t(response.message));
    }
  };

  useEffect(() => {
    socket.on("listConversations", (data) => {
      if (isActive) {
        handleActive();
        setIsActive(false);
        if (data.success) {
          setUserAllConversations(data.data);
        } else {
          toast.error(t(data.message));
        }
      } else {
        setTimeout(() => {
          handleActive();
        }, 500);
      }
    });
  }, []);

  useEffect(() => {
    if (fetchConversation.deleted_conversation) {
      setUserAllConversations(
        userAllConversations.filter(
          (conversation) =>
            conversation._id != fetchConversation.deleted_conversation
        )
      );
      setUserSelling(
        userSelling.filter(
          (conversation) =>
            conversation._id != fetchConversation.deleted_conversation
        )
      );
      setUserBuying(
        userBuying.filter(
          (conversation) =>
            conversation._id != fetchConversation.deleted_conversation
        )
      );
    }
  }, [props]);

  useEffect(() => {
    let conversations = [];
    let userType;
    userAllConversations.map((conversation) => {
      userType = conversation.seller._id == user._id ? "seller" : "buyer";
      conversations.push({
        id: conversation._id,
        avatar: conversation.product.image,
        sellerId: conversation.seller._id,
        userImage:
          userType == "seller"
            ? conversation.buyer.image
            : conversation.seller.image,
        userId:
          userType == "seller"
            ? conversation.buyer._id
            : conversation.seller._id,
        alt: "image",
        title:
          userType == "seller"
            ? conversation.buyer.name
            : conversation.seller.name,
        subtitle: conversation.product.title,
        date: new Date(conversation.updated_at),
        userType: userType,
        sellerBlocked: conversation.seller_blocked,
        buyerBlocked: conversation.buyer_blocked,
        unread: 0,
      });
      return conversation;
    });
    setConversationList(conversations);
  }, [userAllConversations]);

  useEffect(() => {
    let conversations = [];
    let userType;
    userSelling.map((conversation) => {
      userType = conversation.seller._id == user._id ? "seller" : "buyer";
      conversations.push({
        id: conversation._id,
        avatar: conversation.product.image,
        sellerId: conversation.seller._id,
        userImage:
          userType == "seller"
            ? conversation.buyer.image
            : conversation.seller.image,
        userId:
          userType == "seller"
            ? conversation.buyer._id
            : conversation.seller._id,
        alt: "image",
        title:
          userType == "seller"
            ? conversation.buyer.name
            : conversation.seller.name,
        subtitle: conversation.product.title,
        date: new Date(conversation.updated_at),
        userType: userType,
        sellerBlocked: conversation.seller_blocked,
        buyerBlocked: conversation.buyer_blocked,
        unread: 0,
      });
      return conversation;
    });
    setUserSellingList(conversations);
  }, [userSelling]);

  useEffect(() => {
    let conversations = [];
    let userType;
    userBuying.map((conversation) => {
      userType = conversation.seller._id == user._id ? "seller" : "buyer";
      conversations.push({
        id: conversation._id,
        avatar: conversation.product.image,
        sellerId: conversation.seller._id,
        userImage:
          userType == "seller"
            ? conversation.buyer.image
            : conversation.seller.image,
        userId:
          userType == "seller"
            ? conversation.buyer._id
            : conversation.seller._id,
        alt: "image",
        title:
          userType == "seller"
            ? conversation.buyer.name
            : conversation.seller.name,
        subtitle: conversation.product.title,
        date: new Date(conversation.updated_at),
        userType: userType,
        sellerBlocked: conversation.seller_blocked,
        buyerBlocked: conversation.buyer_blocked,
        unread: 0,
      });
      return conversation;
    });
    setUserBuyingList(conversations);
  }, [userBuying]);

  useEffect(() => {
    // socket.on("messageSend", (message) => {

    // });

    let getUserConversations = async () => {
      const response = await Service.Request(
        "/chat_service/conversations",
        {},
        "GET"
      );
      if (response.success) {
        setUserAllConversations(response.data);
      }
    };

    getUserConversations();
  }, []);

  useEffect(() => {
    let getUserSellingConversations = async () => {
      const response = await Service.Request(
        "/chat_service/conversations/selling",
        {},
        "GET"
      );
      if (response.success) {
        setUserSelling(response.data);
      }
    };

    getUserSellingConversations();
  }, []);

  useEffect(() => {
    let getUserBuyingConversations = async () => {
      const response = await Service.Request(
        "/chat_service/conversations/buying",
        {},
        "GET"
      );
      if (response.success) {
        setUserBuying(response.data);
      }
    };

    getUserBuyingConversations();
  }, []);

  useEffect(() => {
    let getBlockedUsers = async () => {
      const response = await Service.Request(
        "/chat_service/conversations/blocked",
        {},
        "GET"
      );
      if (response.success) {
        setBlockedUsers(response.data);
      }
    };

    getBlockedUsers();
  }, []);
  useEffect(() => {
    let blockedUserArray = [];
    blockedUsers.map((user) => {
      blockedUserArray.push({
        id: user._id,
        avatar: user.image,
        alt: "image",
        title: unblockButton(user._id, user.name),
        date: "",
        unread: 0,
      });
      return user;
    });
    setBlockedUserList(blockedUserArray);
  }, [blockedUsers]);

  const clicked = (conversation) => {
    let conversationObject = {
      id: conversation.id,
      title: conversation.title,
      image: conversation.userImage,
      userId: conversation.userId,
      userType: conversation.userType,
      sellerBlocked: conversation.sellerBlocked,
      buyerBlocked: conversation.buyerBlocked,
      sellerId: conversation.sellerId,
    };
    dispatch(setConversation(conversationObject));
    props.reduxUpdated(conversationObject);
  };

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <Card className={classes.root}>
          <CardHeader
            title={t("Chats")}
            avatar={
              <Avatar
                aria-label="recipe"
                src={user.image}
                className={classes.avatar}
              />
            }
          />
        </Card>
        <Paper square>
          <TabList
            onChange={handleChange}
            aria-label="simple tabs example"
            className="TabWidth"
          >
            <Tab title={t("All")} label={t("All")} value="1" />
            <Tab title={t("Selling")} label={t("Selling")} value="2" />
            <Tab title={t("Buying")} label={t("Buying")} value="3" />
            <Tab title={t("Blocked")} label={t("Blocked")} value="4" />
          </TabList>
        </Paper>
        <TabPanel value="1" className="chatProfiles">
          <ChatList
            className="chat-list"
            style={{
              background: "/images/avatar.png",
              height: "30px",
              width: "30px",
            }}
            dataSource={conversationList}
            onClick={clicked}
            onAvatarError={(e) => {
              e.avatar = "/images/avatar.png";
            }}
            lazyLoadingImage="/images/no-image.jpg"
          />
        </TabPanel>
        <TabPanel className="chatProfiles" value="2">
          <ChatList
            className="chat-list"
            style={{
              background: "/images/avatar.png",
              height: "30px",
              width: "30px",
            }}
            dataSource={userSellingList}
            onClick={clicked}
            onAvatarError={(e) => {
              e.avatar = "/images/avatar.png";
            }}
            lazyLoadingImage="/images/no-image.jpg"
          />
        </TabPanel>
        <TabPanel className="chatProfiles" value="3">
          <ChatList
            className="chat-list"
            style={{
              background: "/images/avatar.png",
              height: "30px",
              width: "30px",
            }}
            dataSource={userBuyingList}
            onClick={clicked}
            onAvatarError={(e) => {
              e.avatar = "/images/avatar.png";
            }}
            lazyLoadingImage="/images/no-image.jpg"
          />
        </TabPanel>
        <TabPanel className="chatProfiles" value="4">
          <ChatList
            className="chat-list"
            style={{
              background: "/images/avatar.png",
              height: "30px",
              width: "30px",
            }}
            // button={unblockButton}
            dataSource={blockedUserList}
            // onClick={clicked}
            onAvatarError={(e) => {
              e.avatar = "/images/no-image.jpg";
            }}
            lazyLoadingImage="/images/no-image.jpg"
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ChatTabs;
