import React, { useState } from "react";
import { Button, Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
function GetModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, - ${left}%)`,
    marginLeft: `-200px`,
    marginTop: `-100px`,
  };
}

export default function ConfirmationModule(props) {
  const { t } = useTranslation();
  const performAction = () => {
    props.action(props.id);
    props.close();
  };
  const closed = () => {
    props.close();
  };

  const [modalStyle] = useState(GetModalStyle);
  const body = (
    <div style={modalStyle} className="confirmdelpaper">
      <h3 id="simple-modal-tiele" className="headingSize">
        {" "}
        {props.title}
      </h3>
      <p id="simple-modal-description">{props.text}</p>
      <Button
        variant="contained"
        className="yesBtn + primarybg"
        onClick={performAction}
      >
        {t("Yes")}
      </Button>
      <Button variant="contained" onClick={closed}>
        {t("No")}
      </Button>
    </div>
  );
  return (
    <div>
      <Modal
        open={props.open}
        // onClose={props.close}
        aria-labelledby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
