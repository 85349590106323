import React from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginAction, setUser } from "../../actionCreators";
import { useTranslation } from "react-i18next";

export default function (props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //LOGGED IN WITH GOOGLE
  const responseGoogle = async (response) => {
    const result = await Auth.doUserRequest(
      "user_service/register-via-social-media/google",
      {
        name: response.profileObj.name,
        email: response.profileObj.email,
      }
    );

    if (result.success) {
      // toast.success(result.message);
      await Auth.asyncLocalStorage.setItem("user", JSON.stringify(result.data));
      await Auth.asyncLocalStorage.setItem(
        "token",
        JSON.stringify(result.token)
      );
      dispatch(loginAction());
      dispatch(setUser(result.data));
      if (props?.close) {
        props.close();
      }
      if (props?.submit) {
        await props.submit(props?.data);
      }
    } else {
      toast.error(t(result.message) || t("Network error"));
    }
  };

  //LOGGED IN WITH FB
  const responseFacebook = async (response) => {
    const result = await Auth.doUserRequest(
      "user_service/register-via-social-media/facebook",
      {
        name: response.name,
        email: response.email,
      }
    );

    if (result.success) {
      await Auth.asyncLocalStorage.setItem("user", JSON.stringify(result.data));
      await Auth.asyncLocalStorage.setItem(
        "token",
        JSON.stringify(result.token)
      );
      dispatch(loginAction());
      dispatch(setUser(result.data));
      if (props?.close) {
        props.close();
      }
      if (props?.submit) {
        await props.submit(props?.data);
      }
    } else {
      toast.error(t(result.message) || t("Network error"));
    }
  };
  //Handle failure response
  const responseFacebookFailure = (response) => {};
  const responseGoogleFailure = (response) => {};
  return (
    <>
      <span title={t("Continue with Google")}>
        {" "}
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText={t("Continue with Google")}
          onSuccess={responseGoogle}
          onFailure={responseGoogleFailure}
          cookiePolicy={"single_host_origin"}
          disabledStyle
          disabled={props.disabled}
          className="google-login mt-3"
        />
      </span>

      <span title={t("Continue with Facebook")}>
        {" "}
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_ID}
          fields="name,email"
          // onClick={componentClicked}
          isDisabled={props.disabled}
          icon="fa fa-facebook"
          textButton={t("Continue with Facebook")}
          callback={responseFacebook}
          onFailure={responseFacebookFailure}
          cssClass="fb connect mt-3"
          size="medium"
          style={{
            width: "100%",
          }}
        />
      </span>
    </>
  );
}
