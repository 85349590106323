import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./Store";
import "react-toastify/dist/ReactToastify.css";
import { SettingsUIProvider } from "./SettingUIContext";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./i18next";
import { verifyLocale, fetchDefaultLocale } from "./services/Helpers";

export const MainLayout = () => {
  const [locale] = useState(
    verifyLocale()?.length > 0 && window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1]
      : localStorage.getItem("fallbackLanguage")
  );
  const language = locale
    ? locale
    : fetchDefaultLocale()
    ? fetchDefaultLocale()
    : "";
  return (
    <BrowserRouter basename={"/" + language}>
      <CookiesProvider>
        <Provider store={Store}>
          <SettingsUIProvider>
            <I18nextProvider i18n={i18next}>
              <App />
            </I18nextProvider>
          </SettingsUIProvider>
        </Provider>
      </CookiesProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<MainLayout />, document.getElementById("root"));
