import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import { setAddOptions } from "../../actionCreators";
import { categoryLabelTranslations } from "../../services/Helpers";

export default function Filters(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { categories, addOptions } = useSelector((state) => state);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { register, errors } = useForm();

  const handleCategory = (e, v) => {
    if (v?._id) {
      dispatch(setAddOptions({ ...addOptions, category: v._id }));
      setSelectedCategory(v);
      setSubCategories(v.catChilds);
    } else {
      setSubCategories([]);
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      dispatch(
        setAddOptions({ ...addOptions, category: null, subCategory: null })
      );
    }
  };
  const handleSubCategory = (e, v) => {
    if (v?._id) {
      setSelectedSubCategory(v);
      dispatch(setAddOptions({ ...addOptions, subCategory: v._id }));
    } else {
      setSelectedSubCategory(null);
      dispatch(setAddOptions({ ...addOptions, subCategory: null }));
    }
  };

  return (
    <>
      <section className="filters">
        <div className="filtersInner filterPackages">
          <ul className="filters-dropdown">
            <li className="filter-category-dropdown">
              <FormControl>
                <Autocomplete
                  id="category_listing"
                  options={categories}
                  getOptionLabel={(category) =>
                    categoryLabelTranslations(category)
                  }
                  value={selectedCategory}
                  fullWidth
                  onChange={handleCategory}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Select Category")}
                      variant="outlined"
                      name="category"
                      inputRef={register({
                        required: t("Select one option"),
                      })}
                      error={errors.category && errors.category.message !== ""}
                      helperText={
                        errors.category ? errors.category.message : " "
                      }
                    />
                  )}
                />
              </FormControl>
            </li>
            <li className="filter-category-dropdown">
              <FormControl>
                <Autocomplete
                  id="category_listing"
                  options={subCategories}
                  getOptionLabel={(category) =>
                    categoryLabelTranslations(category)
                  }
                  value={selectedSubCategory}
                  fullWidth
                  onChange={handleSubCategory}
                  noOptionsText={t("No category selected")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Select Subcategory")}
                      variant="outlined"
                      name="category"
                      inputRef={register({
                        required: "Select one option",
                      })}
                      error={errors.category && errors.category.message !== ""}
                      helperText={
                        errors.category ? errors.category.message : " "
                      }
                    />
                  )}
                />
              </FormControl>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
