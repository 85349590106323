import React from "react";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditDelete = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const editProduct = () => {
    history.push(`/edit-ad/${props.slug}`);
  };
  const deleteAd = async () => {
    props.deleteAd(props.id);
  };
  const deactivateAd = async () => {
    props.deactivate(props.id);
  };

  return (
    <React.Fragment>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            {props?.favorite ? null : (
              <div>
                <Button {...bindTrigger(popupState)}>
                  <MoreVertIcon />
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    className={props.status !== 1 ? "d-none" : ""}
                    onClick={deactivateAd}
                  >
                    {t("Deactivate")}
                  </MenuItem>
                  <MenuItem
                    className={props.status !== 1 ? "d-none" : ""}
                    onClick={editProduct}
                  >
                    {t("Edit")}
                  </MenuItem>

                  <MenuItem onClick={deleteAd}>{t("Delete")}</MenuItem>
                </Popover>
              </div>
            )}
          </>
        )}
      </PopupState>
    </React.Fragment>
  );
};

export default EditDelete;
