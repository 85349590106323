import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import EmailVerificationModule from "./EmailVerificationModule";
import { useTranslation } from "react-i18next";
import MetaTitle from "../common/MetaTitle";

const useStyles = makeStyles((theme) => ({

}));

export default function App(props) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, watch } = useForm();
  const history = useHistory();
  let email;
  const [formData, setFormData] = useState();
  const [disabled, setDisabled] = useState(false);
  const classes = useStyles();
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal((showModal) => !showModal);
  const password = useRef({});
  password.current = watch("password", "");

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDisabledButton = () => setDisabled((disabled) => !disabled);

  const onSubmit = async (data) => {
    handleDisabledButton();
    localStorage.setItem("email", data.email);
    email = data.email;
    setFormData(data);
    const response = await Auth.doUserRequest("user_service/register", {
      name: data.name,
      email: data.email,
      password: data.password,
    });
    if (response.success) {
      toast.success(t("Verification code sent successfully"));
      setShowModal(true);
      // handleModal();
      // history.push("/login");
    } else {
      toast.error(t(response.message) || t("Network error"));
    }
    setTimeout(() => {
      handleDisabledButton();
    }, 2000);
  };

  return (
    <React.Fragment>
      <MetaTitle title="Register" />
      <EmailVerificationModule
        show={showModal}
        onHide={handleModal}
        email={email}
        close={handleModal}
        submit={onSubmit}
        data={formData}
      />
      <div className="d-flex justify-content-center align-items-center container register-imac-padding">
        <div className="col-md-12 col-xl-5">
          <div className="checkout-info-box" style={{ minHeight: "auto" }}>
            <h5>{t("Register Here")}</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <TextField
                  type="text"
                  id="inputName"
                  className="form-control"
                  placeholder={t("Full Name")}
                  name="name"
                  variant="outlined"
                  label={`${t("Full Name")} *`}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .trimStart()
                      .replace(/[^a-z\s]/gi, "")
                      .toString()
                      .slice(0, 30);
                  }}
                  inputRef={register({
                    required: t("Name is required"),
                    minLength: {
                      value: 3,
                      message: t("Name must be 3 characters long"),
                    },
                    maxLength: 30,
                  })}
                />
                {errors.name && errors.name.message !== "" ? (
                  <span className="error-message-ag">
                    {errors.name.message}
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <TextField
                  type="text"
                  id="inputEmail"
                  label={`${t("Email")} *`}
                  variant="outlined"
                  fullWidth
                  className="form-control"
                  placeholder={t("Email address")}
                  name="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    required: t("Email is required"),
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t("Invalid email"),
                    },
                  })}
                />

                {errors.email && errors.email.message !== "" ? (
                  <span className="error-message-ag">
                    {errors.email.message}
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel
                    shrink={true}
                    htmlFor="outlined-adornment-password"
                  >
                    {`${t("Password")} *`}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    // value={values.password}
                    placeholder={t("Password")}
                    variant="outlined"
                    notched={true}
                    label={`${t("Password")} *`}
                    fullWidth
                    name="password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({
                      required: t("Password is required"),
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message: t(
                          "Password must contain uppercase and lowercase letters and at least one number"
                        ),
                      },
                      minLength: {
                        value: 8,
                        message: t("Password must be 8 characters long"),
                      },
                    })}
                    // onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>

                {errors.password && errors.password.message !== "" ? (
                  <span className="error-message-ag">
                    {errors.password.message}
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel
                    shrink={true}
                    htmlFor="outlined-adornment-showPassword"
                  >
                    {`${t("Confirm password")} *`}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-showPassword"
                    type={values.showConfirmPassword ? "text" : "password"}
                    // value={confirmPassword}
                    variant="outlined"
                    notched={true}
                    label={`${t("Confirm password")} *`}
                    placeholder={t("Confirm password")}
                    name="password_repeat"
                    inputlabelprops={{
                      shrink: true,
                    }}
                    // onChange={(e) => setConfirmPassword(e.target.value)}
                    inputRef={register({
                      validate: (value) =>
                        value === password.current ||
                        t("Passwords do not match"),
                    })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                {errors.password_repeat &&
                errors.password_repeat.message !== "" ? (
                  <span className="error-message-ag">
                    {errors.password_repeat.message}
                  </span>
                ) : null}
              </div>

              <div className="form-row form-group"></div>
              <button
                title={t("Register")}
                type="submit"
                className="btn btn-primary"
                disabled={disabled}
              >
                {t("Register")} <i className="fa fa-angle-right"></i>
              </button>
              <div className="create-new-account">
                <p>{t("Already have an account then")} </p>
                <Link title={t("Login Here")} to="/login">
                  {" "}
                  <i className="fa fa-key" aria-hidden="true"></i>{" "}
                  {t("Login Here")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
