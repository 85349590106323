import React, { useState, useEffect } from "react";
import Service from "../../../services/UserService.js";
import { Table } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { currencyLabel } from "../../../services/Helpers";
import { useSelector } from "react-redux";

export default function PackagesTable({ type }) {
  const { t } = useTranslation();
  const [packages, setPackages] = useState([]);
  const locations = useSelector((state) => state?.locations || {});
  const { settings } = useSelector((state) => ({
    settings: state.settings,
  }));
  let currencySymbol = t(
    currencyLabel(locations?.currencySymbol || settings?.default_currency)
  );

  async function getBoughtPackages(type) {
    const response = await Service.Request(
      `listing_service/user/packages/${type}`,
      {},
      "GET"
    );
    if (response.success) {
      setPackages(response.data);
    }
  }

  useEffect(() => {
    if (type) {
      getBoughtPackages(type);
    }
  }, []);

  const packageType = {
    Feature_ad: "Feature ad",
    More_ad: "Post more ad",
    Refresh_ad: "Boost to top",
  };

  const packageTableListing = () => {
    const boughtPackages = packages.map((_package, index) => {
      const now = moment(new Date());
      const end = moment(_package.package.expiredAt);
      const duration = moment.duration(end.diff(now));
      const daysLeft = parseInt(duration.asDays());
      const createdDate = moment(_package.created_at);
      return (
        <tr key={index}>
          <td data-column="No.">{index + 1}</td>
          <td data-column="Package Name">{_package.package.detail.name}</td>
          <td data-column="Package Type">
            {packageType[_package.package.detail.type]}
          </td>
          <td data-column="Category">
            {_package.package.detail.default
              ? "All"
              : _package.package.detail.category.title +
                " / " +
                _package.package.detail?.subCategory?.title}
          </td>
          <td data-column="Used/Total Ads">
            {_package.package.detail.usedAds +
              "/" +
              _package.package.detail.totalAds}
          </td>
          <td data-column={type === "active" ? t("To Expire") : t("Status")}>
            {type === "active" ? (
              daysLeft === 1 ? (
                daysLeft + " " + t("Day Left")
              ) : (
                daysLeft + " " + t("Days Left")
              )
            ) : _package.package.detail.usedAds ===
              _package.package.detail.totalAds ? (
              <span className="expired-badge bg-success">{t("Availed")}</span>
            ) : (
              <span className="expired-badge">{t | "Expired"}</span>
            )}
          </td>
          <td data-column="Purchase Date">
            {createdDate.format("DD MMM YYYY")}
          </td>
          <td data-column="Price">
            {currencySymbol +
              " " +
              +_package.package.detail.price * +_package.package.quantity}{" "}
          </td>
        </tr>
      );
    });
    return boughtPackages;
  };

  return (
    <>
      {packages.length > 0 ? (
        <Table className="purchasepackages-table">
          <thead>
            <tr>
              <th>{t("No.")}</th>
              <th>{t("Package Name")}</th>
              <th>{t("Package Type")}</th>
              <th>{t("Category")}</th>
              <th>{t("Used/Total Ads")}</th>
              <th>{type === "active" ? t("To Expire") : t("Status")}</th>
              <th>{t("Purchase Date")}</th>
              <th>{t("Price")}</th>
            </tr>
          </thead>
          <tbody>{packageTableListing()}</tbody>
        </Table>
      ) : (
        <h5
          className="d-flex justify-content-center"
          style={{ backgroundColor: "rgb(248, 248, 248)", padding: "25px" }}
        >
          {t(
            `You don't have any ${type === "expire" ? "expired" : type} package`
          )}
        </h5>
      )}
    </>
  );
}
