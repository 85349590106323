import React, { useEffect, useState } from "react";

import Service from "../../services/UserService";
import MetaTitle from "../common/MetaTitle";
import Loader from "../Product/add/Loader";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export function CMSPages() {
  const { slug } = useParams();

  const history = useHistory();
  const [faqPage, setFaqPage] = useState();
  const [pageStatus, setPageStatus] = useState();
  const [PageContent, setPageContent] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (slug) {
      setFaqPage(slug);
      getPageContent(slug);
    }

    window.scrollTo(0, 0);
  }, [slug]);

  const getPageContent = async (slug) => {
    if (slug) {
      const { data } = await Service.Request(
        `/listing_service/cms-page/${slug}`,
        {},
        "GET"
      );

      setPageStatus(data?.status);

      setPageContent(data?.description);
      setMetaDescription(
        data?.meta_description ? data.meta_description : data?.slug
      );
    }
  };

  return (
    <>
      {faqPage && PageContent && pageStatus ? (
        <>
          <MetaTitle title={metaDescription} />
          <div dangerouslySetInnerHTML={{ __html: PageContent }}></div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {Loader === true ? (
            <Loader show="true" />
          ) : (
            pageStatus === false && history.push("/404")
          )}
        </div>
      )}
    </>
  );
}
