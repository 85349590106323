import React from "react";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Avatar, makeStyles } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import NavTabs from "./NavTabs";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import MetaTitle from "../common/MetaTitle";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  ratings: {
    fontSize: "16px",
    fontWeight: "400",
    marginLeft: "30px",
    [theme.breakpoints.down("992")]: {
      marginLeft: "0",
      fontSize: "14px",
    },
  },
  verificationScore: {
    border: "1px solid #ccc",
    padding: "20px",
  },
  googleRound: {
    background: "#00b7e6",
    width: "30px",
    height: "30px",
    display: "inline-block",
    "text-align": "center",
    color: "#fff !important",
    borderRadius: "50px",
    marginRight: "10px",
    marginTop: "4px",
    paddingTop: "7px",
    minWidth: "auto",
    "&:hover": {
      background: "#00b7e6",
    },
  },
  facebookRound: {
    background: "#00b7e6",
    width: "30px",
    height: "30px",
    display: "inline-block",
    "text-align": "center",
    color: "#fff !important",
    borderRadius: "50px",
    marginRight: "10px",
    paddingTop: "7px",
    marginTop: "4px",
    minWidth: "auto",
    "&:hover": {
      background: "#00b7e6",
    },
  },
}));

export default function Profile() {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { user } = useSelector((state) => state);

  const handleClick = (slug) => {
    history.push({
      pathname: `/product/${slug}`,
      state: {
        prevPath: window.location.pathname,
      },
    });
  };

  return (
    <React.Fragment>
      <MetaTitle title="My Profile" />
      <section className="my-account-main site-main profile-imac-padding">
        <Grid container className="my-account">
          <div className="row m-auto w-100">
            <div className="col-lg-12">
              <div className="my-account-inner">
                <div className="left">
                  <Avatar
                    title={user.name}
                    className="autherSize"
                    src={user.image ? user.image : "/images/avatar.jpg"}
                  />
                  <div>
                    <strong className="autherName">{user.name}</strong>
                    <div className="location">{/* <RoomIcon /> */}</div>
                    <div className="location">
                      <EventAvailableIcon /> {`${t("Member")} ${t("Since")} `}
                      {user ? moment(user.created_at).format("MMM YYYY") : null}
                    </div>
                  </div>
                </div>
                <div className={classes.verifyDetail}>&nbsp;</div>
                <div className="FloatIcon">
                  <Link to="/setting" title={t("Settings")}>
                    <SettingsIcon />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <NavTabs />
            </div>
          </div>
        </Grid>
      </section>
    </React.Fragment>
  );
}
