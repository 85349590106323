import React, { useState } from "react";

import {
  Modal,
  Avatar,
  TextField,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import ShareIcon from "@material-ui/icons/Share";
import FacebookIcon from "@material-ui/icons/Facebook";
import MessageIcon from "@material-ui/icons/Message";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import LinkIcon from "@material-ui/icons/Link";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
 
}));

const ShareModule = (props) => {
  const { t } = useTranslation();
  const [copySuccess, setCopySuccess] = useState("Copy");
  const classes = useStyles();
  // const [modalStyle] = React.useState(GetModalStyle);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const body = (
    <div className="paper">
      <Avatar className="shareAuther" src={props.userImage}></Avatar>
      <Typography className="shareHeading">{props.label}</Typography>
      <LinkIcon className="linkIcon" />{" "}
      <span
        className="clipboard"
        onClick={(e) => {
          navigator.clipboard.writeText(process.env.REACT_APP_URL + props.link);
          setCopySuccess("Copied");
          setTimeout(
            function () {
              setCopySuccess("Copy");
            }.bind(this),
            1500
          );
        }}
      >
        {t(copySuccess)}
      </span>
      <TextField
        fullWidth
        value={process.env.REACT_APP_URL + props.link}
        variant="outlined"
        className="textfiledStyle"
      />
      {/* <
              
              className="facebook-share-button"
            >
              <FacebookIcon
                size={"2rem"} // You can use rem value instead of numbers
                round
              />
              <span>Share on Facebook</span>
            </> */}
      <FacebookShareButton
        quote={props.name}
        url={process.env.REACT_APP_URL + props.link}
        imageURL={props.productImage ? props.productImage : props.userImage}
        variant="contained"
        fullWidth
        className="fbShare"
      >
        <span>
          {" "}
          <FacebookIcon className="iconBalance" />{" "}
          {t("Share on Facebook")}
        </span>
      </FacebookShareButton>
      <FacebookMessengerShareButton
        title={props.name}
        url={process.env.REACT_APP_URL + props.link}
        redirectUri={process.env.REACT_APP_URL + props.link}
        imageURL={props.productImage ? props.productImage : props.userImage}
        appId={process.env.REACT_APP_FACEBOOK_ID}
        variant="contained"
        fullWidth
        className="messengerShare"
      >
        <span>
          <MessageIcon className="iconBalance" />{" "}
          {t("Share on Messenger")}
        </span>
      </FacebookMessengerShareButton>
      <TwitterShareButton
        title={props.name}
        url={process.env.REACT_APP_URL + props.link}
        imageURL={props.productImage ? props.productImage : props.userImage}
        variant="contained"
        fullWidth
        className="twShare"
      >
        <span>
          <TwitterIcon className="iconBalance" /> {t("Share on Twitter")}
        </span>
      </TwitterShareButton>
      <EmailShareButton
        title={props.name}
        url={process.env.REACT_APP_URL + props.link}
        imageURL={props.productImage ? props.productImage : props.userImage}
        variant="contained"
        fullWidth
        className="emShare"
      >
        <span>
          <EmailIcon className="iconBalance" />
          {t("Share by email")}
        </span>
      </EmailShareButton>
    </div>
  );
  return (
    <div className={classes.root}>
      <div className="share" onClick={handleOpen}>
        <ShareIcon className="primaryclr" />
        <span>{t("Share")}</span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default ShareModule;
