import React from "react";

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import { inputValidation } from "../../services/Helpers";

export default function CategoryAttributeInput({
  data,
  inputValue,
  error,
  helperText,
  onChange = () => {},
}) {
  const { t } = useTranslation();
  let { _id, title, type, value, unit, required, min_length, max_length } =
    data;

  const inputProps = {
    minLength: min_length,
    maxLength: max_length,
  };
  const _inputValidation = inputValidation(type, max_length);
  const _titleToSlug = (title) =>
    title.toString().toLowerCase().replace(" ", "-");
  if (typeof value === "string") {
    value = value.split(",");
  }

  switch (type) {
    case "options":
      let _options = value ? value?.map((value) => value) : [];

      return (
        <Autocomplete
          fullWidth
          options={_options}
          value={inputValue}
          onChange={(e, v) => {
            onChange(v);
          }}
          getOptionLabel={(attr) => t(attr)}
          renderInput={(params) => (
            <TextField
              {...params}
              name={_titleToSlug(title)}
              label={`${t(title)} ${required ? "*" : ""}`}
              variant="outlined"
              error={error}
              helperText={helperText}
            />
          )}
        />
      );
    default:
      return (
        <div key={_id} className="filters-input mb-3">
          <TextField
            placeholder={t(title)}
            name={_titleToSlug(title)}
            label={`${t(title)} ${required ? "*" : ""}`}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onInput={_inputValidation}
            autoComplete="off"
            fullWidth
            type="text"
            onChange={(e) => {
              onChange(e.target.value);
            }}
            value={inputValue}
            error={error}
            helperText={helperText}
            inputProps={inputProps}
          />
        </div>
      );
  }
}
