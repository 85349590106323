import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Avatar } from "@material-ui/core";
import ShareModule from "../ShareModule";
import NavTabs from "./NavTabs";
import Service from "../../../services/UserService";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import MetaTitle from "../../common/MetaTitle";
import { useTranslation } from "react-i18next";

export default function PublicProfile() {
  const { t } = useTranslation();
  const { userId } = useParams();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [userSelling, setUserSelling] = useState([]);
  const [userSold, setUserSold] = useState([]);

  async function getUserDetail() {
    let url = `user_service/get-user-details/${userId}`;
    const response = await Service.Request(url, "", "GET");
    if (response.success && response.data) {
      setUser(response.data.user);
      setUserSelling(response.data.products.selling);
      setUserSold(response.data.products.sold);
    } else {
      history.push("/404");
    }
  }
  useEffect(() => {
    getUserDetail();
  }, [userId]);

  return (
    <React.Fragment>
      <MetaTitle title={user?.name} />
      <section className="my-account-main site-main">
        <Grid container className="my-account">
          <div className="row m-auto w-100">
            <div className="col-lg-12">
              <div className="my-account-inner">
                <div className="left">
                  <Avatar
                    className="autherSize"
                    src={user && user.image ? user.image : null}
                  />
                  <div>
                    <strong className="autherName">
                      {user ? user.name : null}
                    </strong>
                    <div className="location">{/* <RoomIcon /> */}</div>
                    <div className="location">
                      <EventAvailableIcon /> {`${t("Member")} ${t("Since")} `}
                      {user ? moment(user.created_at).format("MMM YYYY") : null}
                    </div>
                  </div>
                </div>

                <div className="ShareModule">
                  <ShareModule
                    label={t("Share Profile")}
                    link={window.location.pathname}
                    userImage={user.image}
                    name={user.name}
                  />
                </div>
              </div>
            </div>
          </div>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <NavTabs selling={userSelling} sold={userSold} />
          </Grid>
        </Grid>
      </section>
    </React.Fragment>
  );
}
