import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Auth from "../../../services/UserService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MetaTitle from "../../common/MetaTitle";
import Service from "../../../services/UserService";

export default function App(props) {
  const { t } = useTranslation();
  const paramLocale = Service.getLocale();
  //   const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [disabled, setDisabled] = useState(false);
  const handledisabled = () => setDisabled((disabled) => !disabled);
  const onSubmit = async (data) => {
    handledisabled();
    const response = await Auth.doUserRequest(
      `user_service/forget-password?languageLocale=${paramLocale}`,
      {
        email: data.email,
      }
    );
    if (response.success) {
      toast.success(t(response.message));
      history.push("/login");
    } else {
      handledisabled();
      toast.error(t(response.message));
    }
  };
  return (
    <React.Fragment>
      <MetaTitle title="Forgot password" />
      <div className="d-flex justify-content-center align-items-center container imac-forget-balance">
        <div className="col-md-12 col-xl-5">
          <div className="checkout-info-box" style={{ minHeight: "auto" }}>
            <Link to="/login">
              <i
                className="fa fa-arrow-left"
                style={{ marginBottom: "20px", fontSize: "18px"}}
              ></i>
            </Link>
            <h5>{t("Please enter your email address")}</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <TextField
                  type="text"
                  id="inputEmail"
                  label={t("Email")}
                  variant="outlined"
                  fullWidth
                  className="form-control"
                  placeholder={t("Email address")}
                  name="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    required: t("Email is required"),
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t("Invalid email"),
                    },
                  })}
                />
                {errors.email && errors.email.message !== "" ? (
                  <span className="error-message-ag">
                    {errors.email.message}
                  </span>
                ) : null}
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={disabled}
              >
                {t("Submit")}
                <i className="fa fa-angle-right"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
