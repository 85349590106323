import React from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export function Input({
  type = 'text',
  label,
  className = 'form-control',
  placeholder,
  name,
  inputRef,
  errors,
}) {
  const { t } = useTranslation();
  return (
    <div className="form-group">
      <TextField
        type={type}
        id="inputEmail"
        label={t(label)}
        variant="outlined"
        fullWidth
        className={className}
        placeholder={t(placeholder)}
        name={name}
        InputLabelProps={{ shrink: true }}
        inputRef={inputRef}
      />
      {errors[name] && errors[name].message !== '' && (
        <span className="error-message-ag">{errors[name].message}</span>
      )}
    </div>
  );
}
