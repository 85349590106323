import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ChatTabs from "./chatTabs";
import ChatInterface from "./ChatInterface";
import MetaTitle from "../common/MetaTitle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
}));

const ChatModule = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [conversation, setConversation] = useState({});
  const reduxUpdated = (conversation) => {
    setConversation(conversation);
  };
  return (
    <div className="container">
      <MetaTitle title={t("Chat")} />

      <Grid container className="userSelectormain">
        <Grid item md={5} sm={12} xs={12} className="userSelector">
          <ChatTabs reduxUpdated={reduxUpdated} conversation={conversation} />
        </Grid>
        <Grid item md={7} sm={12} xs={12} className="userChat">
          <ChatInterface
            reduxUpdated={reduxUpdated}
            conversation={conversation}
          />
        </Grid>
      </Grid>
      {/* <MobileChat /> */}
    </div>
  );
};

export default ChatModule;
