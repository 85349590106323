import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Service from "../../services/UserService";
import { useHistory } from "react-router-dom";
import { Button, FormControl, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MetaTitle from "../common/MetaTitle";

const useStyles = makeStyles((theme) => ({}));

export default function App(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const handleDisabledButton = () => setDisabled((disabled) => !disabled);

  const { register, handleSubmit, setValue, errors, reset, formState } =
    useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const onSubmit = async (data) => {
    handleDisabledButton();
    const response = await Service.Request(
      "user_service/contact-us",
      {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
      },
      "post"
    );
    if (response.success) {
      toast.success(t("Thank you for contacting us"));
      reset();
      handleDisabledButton();
    } else {
      toast.error(t(response.message) || t("An Error Occured"));
      handleDisabledButton();
    }
  };
  return (
    <React.Fragment>
      <MetaTitle title="Contact Us" />
      <section className="site-mian pb-0">
        <div className="container-fluid px-0">
          <div className="upload-file-main">
            <div className="row">
              <div className="col-md-12 col-xl-6 mx-auto">
                <h3>{t("Contact Us")}</h3>
                <div className="user-upload-product">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <TextField
                        placeholder={t("Name")}
                        name="name"
                        label={`${t("Name")}*`}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .trimStart()
                            .replace(/[^a-z\s]/gi, "")
                            .toString()
                            .slice(0, 30);
                        }}
                        autoComplete="off"
                        fullWidth
                        inputRef={register({
                          required: t("Name is required"),
                          minLength: {
                            value: 3,
                            message: t("Name must be 3 characters long"),
                          },
                        })}
                        error={errors.name && errors.name.message !== ""}
                        helperText={errors.name ? errors.name.message : " "}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        type="text"
                        id="inputEmail"
                        label={`${t("Email")}*`}
                        variant="outlined"
                        fullWidth
                        className="form-control"
                        placeholder={t("Email address")}
                        name="email"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({
                          required: t("Email is required"),
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: t("Invalid email"),
                          },
                        })}
                        error={errors.email && errors.email.message !== ""}
                        helperText={errors.email ? errors.email.message : " "}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        placeholder={t("Subject")}
                        name="subject"
                        label={`${t("Subject")}*`}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .toString()
                            .slice(0, 70);
                        }}
                        autoComplete="off"
                        fullWidth
                        inputRef={register({
                          required: t("Subject is required"),
                          minLength: {
                            value: 5,
                            message: t("Subject must be 5 characters long"),
                          },
                        })}
                        error={errors.subject && errors.subject.message !== ""}
                        helperText={
                          errors.subject ? errors.subject.message : " "
                        }
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        id="exampleFormControlTextarea1"
                        label={`${t("Message")}*`}
                        multiline
                        rows={4}
                        aria-label="maximum height"
                        name="message"
                        placeholder={t("Message")}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        inputRef={register({
                          required: t("Message is required"),
                          minLength: {
                            value: 20,
                            message: t("Message must be 20 characters long"),
                          },
                        })}
                        color="primary"
                        error={errors.message && errors.message.message !== ""}
                        helperText={
                          errors.message ? errors.message.message : " "
                        }
                      />
                    </div>
                    <FormControl className="postButtonmain" variant="outlined">
                      <Button
                        title={t("Submit")}
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        className="postButton"
                        disabled={disabled}
                      >
                        {t("Submit")}
                      </Button>
                    </FormControl>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
