import React from "react";
import { Grid, Button } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import MyLocationIcon from "@material-ui/icons/MyLocation";

import { Slider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

}));

function valueLabelFormat(value) {
  const [coefficient, exponent] = value
    .toExponential()
    .split("e")
    .map((item) => Number(item));
  return `${Math.round(coefficient)}e^${exponent}`;
}

export default function RangeSlider() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Grid container spacing={3} className={classes.mangeKM}>
        <Grid item xs={1}>
          <HomeIcon />
        </Grid>
        <Grid item xs={10}>
          <Slider
            className={classes.root}
            value={value}
            min={0}
            step={0.1}
            max={6}
            scale={(x) => x ** 10}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            onChange={handleChange}
            ValueLabelDisplay="auto"
            aria-labelledbay="non-linear-slider"
          ></Slider>
        </Grid>
        <Grid item xs={1}>
          <MyLocationIcon />
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.saveLocation + ' saveLocation'}
      >
        Save Location
      </Button>
    </div>
  );
}
