import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "./control/PasswordField";
const useStyles = makeStyles((theme) => ({
  root: {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: "25ch",
    },
  },
}));

export default function VerificationModalContent(props) {
  const { t } = useTranslation();
  const { submitAction, disableSubmit, register, errors } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <form onSubmit={submitAction}>
        <PasswordInput
          className={clsx(classes.margin, classes.textField)}
          label="Password"
          placeholder="Password"
          name="password"
          errors={t(errors)}
          inputRef={register({
            required: t("Password is required"),
          })}
        />
        {errors.password && errors.password.message !== "" ? (
          <span className="error-message-ag">{errors.password.message}</span>
        ) : null}
        <button
          type="submit"
          className="btn btn-primary number-submit-ag"
          disabled={disableSubmit}
        >
          {t("Submit")}
          <i className="fa fa-angle-right"></i>
        </button>
      </form>
    </React.Fragment>
  );
}
