import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import Login from "./../Models";
import Slider from "react-slick";
import { standardPricing, urduPricing } from "../../services/Helpers";
import EditDelete from "./EditDelete";
import Service from "./../../services/UserService";
// import AdItemAttributes from "../Includes/AdItemAttributes";

export default function ProductsModule(props) {
  const { t } = useTranslation();
  const { setting, user, locations } = useSelector((state) => ({
    setting: state.settings,
    user: state.user,
    locations: state.locations,
  }));

  const [products, setProducts] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    appendDots: (dots) => {
      return (
        <MagicSliderDots
          dots={dots}
          numDotsToShow={4}
          dotWidth={30}
          prevNextDotClassName=""
        />
      );
    },
  };

  async function removeFromFavorites(id, index) {
    let url = `posting_service/favorites/${id}`;
    await Service.Request(url, {}, "DELETE");
    let productArray = products.filter((item, i) => i !== index);
    setProducts(productArray);
    props.updateProducts(id, true);
    // props.handleFavorite(productArray);
  }

  useEffect(() => {
    setProducts(props.products);
  }, [props.products]);

  const handleClose = () => setShowLogin(false);

  const locale = localStorage.getItem("defaultLocale");
  const _renderProduct = (product, index) => {
    let _innerProduct;
    _innerProduct = (
      <div>
        <figure title={product.title}>
          <Slider {...settings} className="slider">
            {product?.images.map((image) => {
              return (
                <div className="slider slider-for01" key={index}>
                  <div>
                    <Link
                      to={{
                        pathname: `/product/${product.slug}`,
                        state: {
                          prevPath: window.location.pathname,
                        },
                      }}
                    >
                      <img
                        src={image}
                        alt={product.name}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              );
            })}
          </Slider>
          {props.sold ? (
            <span className="sold-out">
              <EditDelete
                id={product._id}
                status={product.status}
                slug={product.slug}
                sold={props.sold}
                index={index}
                favorite={props.favorite}
                deactivate={props.deactivate}
                deleteAd={props.deleteAd}
              />
            </span>
          ) : null}
          <Link
            to={{
              pathname: `/product/${product.slug}`,
              state: {
                prevPath: window.location.pathname,
              },
            }}
          >
            <figcaption>
              <h4>{product.title}</h4>
              <div className="price-tag">
                {product.is_free || product.price === 0
                  ? t("Free")
                  : locale === "ur_PK"
                  ? urduPricing(product.price, t, setting, locations)
                  : standardPricing(product.price, t, setting, locations)}
              </div>
              {/* TODO: Enable attribute display after fixing the layout */}
              {/* <AdItemAttributes product={product}/> */}
              <p className="text-muted">
                {moment(product.created_at).fromNow()}
              </p>
            </figcaption>
          </Link>
          {product.status === 1 &&
            product.user._id === user._id &&
            !props?.favorite && (
              <button
                title={t("Mark as Sold")}
                className="btn btn-primary"
                onClick={() => {
                  props.sold(product._id);
                }}
              >
                {t("Mark as Sold")}
              </button>
            )}{" "}
          {product.status !== 1 &&
          !props.favorite &&
          product.user._id === user._id ? (
            <button
              title={t("Mark as Active")}
              className="btn btn-primary"
              onClick={() => {
                props.active(product._id);
              }}
            >
              {t("Mark as Active")}
            </button>
          ) : null}
          {props.favorite ? (
            <div
              className="heart"
              onClick={() => {
                removeFromFavorites(product._id);
              }}
            >
              <a title={t("Remove from favorites")}>
                <i className="fa fa-heart" aria-hidden="true"></i>
              </a>
            </div>
          ) : null}
        </figure>
      </div>
    );
    return (
      <>
        <div className="col-md-6 col-xl-4">{_innerProduct}</div>
      </>
    );
  };

  return (
    <>
      <Modal
        show={showLogin}
        onHide={handleClose}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Login close={handleClose} />
      </Modal>
      <div className="row no-gutters">
        {products.length === 0
          ? null
          : products.map((product, i) => {
              return (
                <React.Fragment key={i}>
                  {_renderProduct(
                    product.product ? product.product : product,
                    i
                  )}
                </React.Fragment>
              );
            })}
      </div>
    </>
  );
}
