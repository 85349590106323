import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import FilterModal from "./filterModal";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NestedMenuItem from "material-ui-nested-menu-item";
import {
  categoryLabelTranslations,
  currencyLabel,
} from "../../services/Helpers";

import {
  Button,
  InputAdornment,
  Popover,
  Box,
  FormControl,
  Menu,
  MenuItem,
  Select,
  InputBase,
  TextField,
  withStyles,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  prepareFiltersURL,
  getAllQuery,
  getQueryVariable,
  getRouteParams,
  inputValidation,
} from "../../services/Helpers";
import LocationFilters from "./locationFilters";
import { useTranslation } from "react-i18next";
import { setSelectedCategory } from "../../actionCreators";

const BootstrapInput = withStyles((theme) => ({}))(InputBase);

const useStyles = makeStyles((theme) => ({}));

export default function Filters(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const { category_slug } = useParams();
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [params, setParams] = useState(category_slug);
  const [resetAllPrice, setResetAllPrice] = useState(false);
  let { categories, settings, locations } = useSelector((state) => state);
  const [category, setCategory] = useState("all");
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [sorted, setSort] = useState("sort");
  const history = useHistory();
  const [priceError, setPriceError] = useState("");
  const [anchorEl, setAnchorE1] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [selectedCategoryTitle, setSelectedCategoryTitle] =
    useState("All Categories");

  const handleClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorMenu(null);
  };

  const handlePopoverOpen = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handlePopoverClose = (event) => {
    setAnchorE1(null);
  };
  useEffect(() => {
    dispatch(
      setSelectedCategory(category !== "all" ? JSON.parse(category) : category)
    );
  }, [category]);

  const handleCategory = (e, v) => {
    let categoryObject = e.target.value ? e.target.value : v;
    let categoryTitle =
      categoryObject === "all"
        ? "All Categories"
        : categoryLabelTranslations(JSON.parse(categoryObject));
    setSelectedCategoryTitle(categoryTitle);
    let _cat =
      categoryObject !== "all" && typeof categoryObject === "string"
        ? JSON.parse(categoryObject)
        : categoryObject;

    setCategory(
      typeof _cat === "string" || _cat === "all" ? _cat : JSON.stringify(_cat)
    );

    const cat_slug = _cat?.slug ? _cat?.slug : "all";
    let _filterUrl = `/category/${cat_slug}${prepareFiltersURL([], true)}`;
    history.push(getRouteParams(_filterUrl, _cat));
    handleClose();
  };

  useEffect(() => {
    if (props.location.category && props.location.category !== "all") {
      let _category = props.location.category;
      setCategory(JSON.stringify(_category));
      setSelectedCategoryTitle(categoryLabelTranslations(_category));
      setCategoryAttributes(_category.attributes);
    } else {
      setCategory(
        props?.location?.category ? props?.location?.category : "all"
      );
      setSelectedCategoryTitle(t("All Categories"));
      setCategoryAttributes([]);
    }
  }, [props.location]);

  useEffect(() => {
    if (minPrice == "" && maxPrice == "" && resetAllPrice) {
      handlePrice();
    }
  }, [minPrice, maxPrice]);
  useEffect(() => {
    let _queryParams = getAllQuery(true);
    setSort(_queryParams["sort"] ? _queryParams["sort"] : "sort");
    setMinPrice(_queryParams["min"]);
    setMaxPrice(_queryParams["max"]);

    if (params !== "all") {
      let categoryObject;
      categories.map((category) => {
        let filteredCat = [];
        if (category.catChilds) {
          filteredCat = category.catChilds.map((childCategory) => {
            if (childCategory.slug === category_slug) {
              categoryObject = childCategory;
              return childCategory;
            }
          });
        }

        if (category.slug === category_slug) {
          categoryObject = category;
          return category;
        }
        return filteredCat;
      });
      if (categoryObject) {
        setCategory(JSON.stringify(categoryObject));
        setSelectedCategoryTitle(categoryLabelTranslations(categoryObject));
        let _filterUrl = `/category/${categoryObject.slug}${prepareFiltersURL(
          []
        )}`;
        history.push(getRouteParams(_filterUrl, categoryObject));
      }
    } else {
      let ifQuery = prepareFiltersURL([]) === "?" ? "" : prepareFiltersURL([]);
      let _filterUrl = `/category/all${ifQuery}`;
      history.push(getRouteParams(_filterUrl, "all"));
    }
  }, [categories]);

  const handleSort = (e) => {
    setSort(e.target.value);
    let isSort = e.target.value === "sort" ? false : e.target.value;
    let _cat = category && category !== "all" ? JSON.parse(category) : "all";
    const cat_slug = _cat?.slug ? _cat?.slug : "all";
    let _filterUrl = `/category/${cat_slug}${prepareFiltersURL([
      { sort: isSort },
    ])}`;
    history.push(getRouteParams(_filterUrl, _cat));
  };

  const handlePrice = (data, e, products) => {
    let min = minPrice ? minPrice : "";
    let max = maxPrice ? maxPrice : "";
    if (products === "free") {
      min = 0;
      max = 0;
    } else if (products === "all") {
      min = "";
      max = "";
    }
    let _cat = category && category !== "all" ? JSON.parse(category) : "all";
    const cat_slug = _cat?.slug ? _cat?.slug : "all";
    let _filterUrl = `/category/${cat_slug}${prepareFiltersURL([
      { min },
      { max },
    ])}`;
    history.push(getRouteParams(_filterUrl, _cat));
    handlePopoverClose();
  };

  const handleAttr = (e) => {
    const { name, value } = e.target;

    let _cat = category && category !== "all" ? JSON.parse(category) : "all";
    let _filterUrl = `/category/${_cat.slug}${prepareFiltersURL([
      { [name]: value },
    ])}`;

    history.push(getRouteParams(_filterUrl, _cat, e.target));
  };

  const resetPrice = () => {
    setMinPrice("");
    setMaxPrice("");
    setResetAllPrice(true);
  };

  const _renderAttributeFilter = (attribute) => {
    let {
      _id,
      category_id,
      title,
      value: options,
      type,
      min_length,
      max_length,
    } = attribute;
    if (typeof options === "string") {
      options = options?.split(",");
    }

    let _fieldName = `attr[${_id}]`;
    let _queryValue = getQueryVariable(_fieldName);
    let _attrValue =
      !_queryValue || _queryValue == "" ? "" : decodeURIComponent(_queryValue);

    let _inputValidation = inputValidation(type);

    const inputProps = {
      minLength: min_length,
      maxLength: max_length,
    };
    switch (type) {
      case "options":
        let _dropdownOptions = options ? options.map((value) => value) : [];

        return (
          <li key={_id} className="filter-category-dropdown">
            <FormControl>
              <Select
                title={t(title)}
                name={_fieldName}
                value={_attrValue == "" ? "all" : _attrValue}
                placeholder={t(title)}
                onChange={handleAttr}
                input={<BootstrapInput />}
                className="sortStyleSelect"
              >
                <MenuItem key="all" className="selectDown" value="all">
                  {t("Choose" + " " + title)}
                </MenuItem>

                {_dropdownOptions.length > 0
                  ? _dropdownOptions.map((v) => {
                      return (
                        <MenuItem
                          title={t(v)}
                          className="selectDown"
                          options={v}
                          key={v}
                          value={v}
                        >
                          {t(v)}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </li>
        );
      default:
        return (
          <div key={_id} className="filters-input mb-3">
            <TextField
              variant="standard"
              id={_id}
              label={t(title)}
              name={_fieldName}
              value={_attrValue}
              placeholder={t(title)}
              onChange={handleAttr}
              onInput={_inputValidation}
              inputProps={inputProps}
            />
          </div>
        );
    }
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setDisabled((value = true) => !value);
    handlePrice("", "", disabled ? "all" : "free");
  };
  return (
    <React.Fragment>
      {/* <div className="showing-item total-items">
        <p>40 Search Results of Electronics </p>
      </div> */}
      <section className="filters">
        <div className="filtersInner">
          <div className="container-fluid px-0">
            <ul className="filters-dropdown">
              <li className="filter-category-dropdown">
                <FormControl>
                  <Button
                    title={selectedCategoryTitle}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className="sortStyleSelect categoryFilter"
                  >
                    {selectedCategoryTitle}
                    <ArrowDropDownIcon className="indictorColor" />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorMenu}
                    keepMounted
                    open={Boolean(anchorMenu)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      className="selectDown"
                      value="all"
                      onClick={(e) => {
                        handleCategory(e, "all");
                      }}
                    >
                      {t("All Categories")}
                    </MenuItem>
                    {categories?.length > 0 &&
                      categories.map((parentCategory, index) => {
                        return parentCategory.catChilds?.length > 0 ? (
                          <NestedMenuItem
                            key={index}
                            title={categoryLabelTranslations(parentCategory)}
                            label={categoryLabelTranslations(parentCategory)}
                            parentMenuOpen={Boolean(anchorMenu)}
                            // parentMenuOpen={true}
                            value={JSON.stringify(parentCategory)}
                            onClick={(e) => {
                              handleCategory(e, JSON.stringify(parentCategory));
                            }}
                          >
                            {parentCategory?.catChilds.map((childCategory) => (
                              <MenuItem
                                title={categoryLabelTranslations(childCategory)}
                                value={JSON.stringify(childCategory)}
                                key={childCategory._id}
                                onClick={(e) => {
                                  handleCategory(
                                    e,
                                    JSON.stringify(childCategory)
                                  );
                                }}
                              >
                                {categoryLabelTranslations(childCategory)}
                              </MenuItem>
                            ))}
                          </NestedMenuItem>
                        ) : (
                          <MenuItem
                            title={categoryLabelTranslations(parentCategory)}
                            className="selectDown"
                            value={JSON.stringify(parentCategory)}
                            key={parentCategory._id}
                            onClick={(e) => {
                              handleCategory(e, JSON.stringify(parentCategory));
                            }}
                          >
                            {categoryLabelTranslations(parentCategory)}
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </FormControl>
              </li>
              <li className="filter-price-down">
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <div>
                      <button
                        title={t("Price")}
                        variant="contained"
                        color="primary"
                        value="price"
                        {...bindTrigger(popupState)}
                        className="pricingText"
                        onClick={handlePopoverOpen}
                      >
                        {t("Price")}{" "}
                        <ArrowDropDownIcon className="indictorColor" />
                      </button>

                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handlePopoverClose}
                        className="filter-price-dropdown"
                      >
                        <Box p={2} className="boxSize">
                          <form onSubmit={handleSubmit(handlePrice)}>
                            <TextField
                              id="min_price"
                              label={t("Min")}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9]/, "")
                                  .toString()
                                  .slice(0, 15);
                              }}
                              style={{ margin: "0 0 20px 0", border: "none" }}
                              variant="outlined"
                              placeholder={t("Min")}
                              className="priceStyle"
                              margin="normal"
                              onChange={(e) => {
                                if (
                                  parseFloat(maxPrice) <
                                  parseFloat(e.target.value)
                                ) {
                                  setPriceError(
                                    t(
                                      "The min price can’t be higher than the max price"
                                    )
                                  );
                                } else {
                                  setPriceError("");
                                }
                                setMinPrice(e.target.value);
                              }}
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={minPrice}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {t(
                                      currencyLabel(
                                        locations?.currencySymbol ||
                                          settings?.default_currency
                                      )
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              name="min_price"
                            />
                            <TextField
                              id="max_price"
                              label={t("Max")}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9]/, "")
                                  .toString()
                                  .slice(0, 15);
                              }}
                              style={{ margin: "0 0 20px 0", border: "none" }}
                              variant="outlined"
                              placeholder={t("Max")}
                              className="priceStyle"
                              value={maxPrice}
                              onChange={(e) => {
                                if (
                                  parseFloat(minPrice) >
                                  parseFloat(e.target.value)
                                ) {
                                  setPriceError(
                                    t(
                                      "The min price can’t be higher than the max price"
                                    )
                                  );
                                } else {
                                  setPriceError("");
                                }
                                setMaxPrice(e.target.value);
                              }}
                              margin="normal"
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {t(
                                      currencyLabel(
                                        locations?.currencySymbol ||
                                          settings?.default_currency
                                      )
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              name="max_price"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  color="primary"
                                  onChange={handleChange}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label={t("Free")}
                            />
                            <p>
                              {priceError !== "" ? (
                                <span className="error-message-ag">
                                  {priceError}
                                </span>
                              ) : null}
                            </p>
                            <div className="d-flex justify-content-center">
                              {minPrice || maxPrice ? (
                                <Button
                                  title={t("Reset")}
                                  variant="contained"
                                  color="primary"
                                  className={classes.BtnStyle + " BtnStyle"}
                                  onClick={resetPrice}
                                  disabled={disabled}
                                >
                                  {t("Reset")}
                                </Button>
                              ) : null}

                              <Button
                                title={t("Apply")}
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.BtnStyle + " BtnStyle"}
                                disabled={
                                  (priceError !== "" ||
                                  (minPrice === "" && maxPrice === "") ||
                                  (minPrice === undefined &&
                                    maxPrice === undefined)
                                    ? true
                                    : false) || disabled
                                }
                              >
                                {t("Apply")}
                              </Button>
                            </div>
                          </form>
                        </Box>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </li>

              <li className="filter-cat-dropdown">
                <FormControl className={classes.margin}>
                  <Select
                    title={t(
                      sorted == "sort"
                        ? t("Sort")
                        : sorted == "price_desc"
                        ? t("High to Low")
                        : t("Low to High")
                    )}
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    name="sort"
                    onChange={handleSort}
                    value={sorted}
                    input={<BootstrapInput />}
                    className="sortStyleSelect"
                  >
                    <MenuItem
                      title={t("Sort by")}
                      className="selectDown"
                      value="sort"
                    >
                      {t("Sort by")} ({t("Most Recent")}){" "}
                    </MenuItem>
                    <MenuItem
                      title={t("High to Low")}
                      className="selectDown"
                      value="price_desc"
                    >
                      {t("High to Low")}{" "}
                    </MenuItem>
                    <MenuItem
                      title={t("Low to High")}
                      className="selectDown"
                      value="price_asc"
                    >
                      {t("Low to High")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </li>
              <LocationFilters category={category} />
              <li className="filter-location-dropdown d-none">
                <FilterModal />
              </li>
              <li className="filters-line-break"></li>
              {categoryAttributes?.length > 0 &&
                categoryAttributes.map(_renderAttributeFilter)}
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
