import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "./components/Includes/footer";
import Navbar from "./components/Includes/navbar";
// import Sidebar from "./components/Includes/sidebar";
import Home from "./components/Home";
import Product from "./components/Product";
import Detail from "./components/Details";
import Profile from "./components/Profile";
import Login from "./components/Auth";
import Register from "./components/Register";
import Logout from "./components/Auth/logout";
import AddProduct from "./components/Product/add";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SecuredRoute from "./components/SecureRoute";
import RouteBeforeLogin from "./components/SecureRoute/beforeLogin";
import ForgetPass from "./components/Auth/Forget/forget";
import RenewPass from "./components/Auth/Forget/renew";
import NotFound from "./components/404";
import EditProduct from "./components/Product/edit";
import Setting from "./components/Profile/SettingsModule";
import PublicProfile from "./components/Profile/Public";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core";
import ChatModule from "./components/Chat";
import ProductSuccess from "./components/Product/postSubmitted";
import ContactUs from "./components/ContactUs/contactUs";
import WebNameSuggestion from "./components/NameSuggestion/NameSuggestion";
import moment from "moment";
import addOption from "./components/AdsPackages/addOptions";
import discountPackages from "./components/AdsPackages/discountPackages";
import ViewCart from "./components/AdsPackages/viewcart";
import { getLocale } from "./services/Helpers";
import { CMSPages } from "./components/Pages/CMSPages";
import purchasePackages from "./components/Profile/PurchasePackages";
function App() {
  const [languageDirection, setLanguageDirection] = useState("ltr");
  const languages = useSelector((state) => state.languages);
  let dir = "";
  useEffect(() => {
    if (languages?.length > 0) {
      const locale = localStorage.getItem("defaultLocale");
      dir = languages.find((l) => l.defaultLocale === locale)?.direction;
      if (dir) {
        document.documentElement.setAttribute("dir", dir);
      }
      setLanguageDirection(dir);
    }
  }, [languages]);
  moment.locale(getLocale());
  return (
    <React.Fragment>
      <ToastContainer
        position={
          languageDirection && languageDirection == "rtl"
            ? "top-left"
            : "top-right"
        }
      />
      <div className="main-wrapper">
        <ThemeProvider theme={theme}>
          {/* <Sidebar /> */}
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/category/:category_slug" component={Product} />
            <Route exact path="/product/:slug" component={Detail} />
            <SecuredRoute exact path="/package/options" component={addOption} />
            <SecuredRoute
              exact
              path="/choose/packages"
              component={discountPackages}
            />
            <SecuredRoute
              exact
              path="/purchased/packages"
              component={purchasePackages}
            />
            <SecuredRoute exact path="/view/cart" component={ViewCart} />
            <SecuredRoute exact path="/chat" component={ChatModule} />
            <SecuredRoute
              exact
              path="/profile/:favorites?"
              component={Profile}
            ></SecuredRoute>
            <SecuredRoute
              path="/ad-posted"
              exact
              component={ProductSuccess}
            ></SecuredRoute>
            <Route
              exact
              path="/user-profile/:userId"
              component={PublicProfile}
            />
            <SecuredRoute
              path="/setting"
              exact
              component={Setting}
            ></SecuredRoute>
            <SecuredRoute
              path="/edit-ad/:slug"
              exact
              component={EditProduct}
            ></SecuredRoute>
            <SecuredRoute
              path="/logout"
              exact
              component={Logout}
            ></SecuredRoute>
            <RouteBeforeLogin
              exact
              path="/login"
              component={Login}
            ></RouteBeforeLogin>
            <Route exact path="/forget-password" component={ForgetPass} />
            <Route
              path="/reset-password/:token"
              component={RenewPass}
            ></Route>
            <RouteBeforeLogin
              exact
              path="/register"
              component={Register}
            ></RouteBeforeLogin>
            <Route exact path="/post-ad" component={AddProduct} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route
              exact
              path="/name-suggestion"
              component={WebNameSuggestion}
            />
            <Route exact path="/page/:slug" component={CMSPages} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

export default App;
