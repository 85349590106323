import React, { useRef, useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../../../services/UserService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MetaTitle from "../../common/MetaTitle";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../../actionCreators";

export default function App(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();
  const useStyles = makeStyles((theme) => ({
  }));
  
  const classes = useStyles();
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  useEffect(() => {
    if(token){
      dispatch(logoutAction());
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }

  },[]);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const password = useRef({});
  password.current = watch("password", "");
  const { token } = useParams();
  const handleDisabled = () => setDisabled((disabled) => !disabled);
  const onSubmit = async (data) => {
    handleDisabled();
    const response = await Auth.doUserRequest(
      `user_service/reset-password/${token}`,
      {
        new_password: data.password,
      }
    );
    if (response.success) {
      toast.success(t(response.message));
      history.push("/login");
    } else {
      handleDisabled();
      toast.error(t(response.message));
    }
  };
  return (
    <React.Fragment>
      <MetaTitle title="Reset password" />
      <div className="d-flex justify-content-center align-items-center container">
        <div className="col-md-12 col-xl-5">
          <div className="checkout-info-box" style={{ minHeight: "auto" }}>
            <h5>{t("Reset Password")}</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel
                    shrink={true}
                    htmlFor="outlined-adornment-password"
                  >
                    {t("New password")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    // value={password}
                    notched={true}
                    label={t("New password")}
                    placeholder={t("New password")}
                    name="password"
                    inputlabelprops={{
                      shrink: true,
                    }}
                    // onChange={(e) => setPassword(e.target.value)}
                    inputRef={register({
                      required: t("Password is required"),
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message: t(
                          "Password must contain uppercase and lowercase letters and at least one number"
                        ),
                      },
                      minLength: {
                        value: 8,
                        message: t("Password must be 8 characters long"),
                      },
                    })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>

                {errors.password && errors.password.message !== "" ? (
                  <span className="error-message-ag">
                    {errors.password.message}
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel
                    shrink={true}
                    htmlFor="outlined-adornment-showPassword"
                  >
                    {t("Confirm password")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-showPassword"
                    type={values.showConfirmPassword ? "text" : "password"}
                    // value={confirmPassword}
                    notched={true}
                    variant="outlined"
                    label={t("Confirm password")}
                    placeholder={t("Confirm password")}
                    name="password_repeat"
                    inputlabelprops={{
                      shrink: true,
                    }}
                    // onChange={(e) => setConfirmPassword(e.target.value)}
                    inputRef={register({
                      validate: (value) =>
                        value === password.current ||
                        t("Passwords do not match"),
                    })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                {errors.password_repeat &&
                errors.password_repeat.message !== "" ? (
                  <span className="error-message-ag">
                    {errors.password_repeat.message}
                  </span>
                ) : null}
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={disabled}
              >
                {t("Reset Password")} <i className="fa fa-angle-right"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
