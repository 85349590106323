import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "./control/InputField";

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function (props) {
  const { ldap, register, reset, errors } = props;
  console.log("🚀 ~ file: LdapLogin.js:10 ~ ldap:", ldap);
  const { t } = useTranslation();
  useEffect(() => {
    reset();
  }, [ldap]);
  return (
    <>
      <h5>{ldap ? t("LDAP Login") : t("Login Here")}</h5>
      {ldap ? (
        <Input
          label={"Username"}
          placeholder={"Username"}
          name="email"
          inputRef={register({ required: t("Username is required") })}
          errors={errors}
        />
      ) : (
        <Input
          label="Email"
          placeholder={"Email address"}
          name="email"
          inputRef={register({
            required: t("Email is required"),
            pattern: {
              value: emailPattern,
              message: t("Invalid email"),
            },
          })}
          errors={errors}
        />
      )}
    </>
  );
}
