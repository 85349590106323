import React, { useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "lightgallery.js/dist/css/lightgallery.css";
import "./style.css";

const thumbItems = (images, [setThumbIndex, setThumbAnimation]) => {
  return images.map((item, i) => (
    <div
      className="thumb"
      onClick={() => (setThumbIndex(i), setThumbAnimation(true))}
    >
      <div className="item" data-value={i}>
        <img src={item} />
      </div>
    </div>
  ));
};
const responsive = {
  variableWidth: true,
  centerMode: false,
  1900: { items: 5 },
  1400: { items: 4 },
  991: { items: 3 },
  767: { items: 5 },
  575: { items: 4 },
  380: { items: 3 },
  350: { items: 2 },
  0: { items: 2 },
};

const Carousel = ({ images }) => {
  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [thumbs, setThumbs] = useState([]);
  const items = images.map((image, index) => {
    return (
      <div
        className="item"
        data-value={index}
        onClick={(e) => handleOpen(index)}
      >
        <img src={image} />
      </div>
    );
  });

  const handleOpen = (index) => {
    setPhotoIndex(index);
    setIsOpen((isOpen) => !isOpen);
  };

  const slideNext = () => {
    if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    }
  };

  const slidePrev = () => {
    if (!thumbAnimation && thumbIndex > 0) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex - 1);
    }
  };

  const syncMainBeforeChange = (e) => {
    setMainAnimation(true);
  };

  const syncMainAfterChange = (e) => {
    setMainAnimation(false);

    if (e.type === "action") {
      setThumbIndex(e.item);
      setThumbAnimation(false);
    } else {
      setMainIndex(thumbIndex);
    }
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };

  useEffect(() => {
    setThumbs(thumbItems(images, [setThumbIndex, setThumbAnimation]));
  }, [images]);

  return (
    <>
      {isOpen && (
        <>
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={
              images.length > 1
                ? images[(photoIndex + 1) % images.length]
                : null
            }
            prevSrc={
              images.length > 1
                ? images[(photoIndex + images.length - 1) % images.length]
                : null
            }
            imageTitle={photoIndex + 1 + "/" + images.length}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => {
              let index = (photoIndex - 1) % images.length;
              index = index < 0 ? images.length - 1 : index;
              setPhotoIndex(index);
            }}
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex) => (photoIndex + 1) % images.length)
            }
          />
        </>
      )}
      <AliceCarousel
        activeIndex={mainIndex}
        animationType="fadeout"
        disableDotsControls
        disableButtonsControls
        infinite
        items={items}
        mouseTracking={images.length === 1 ? false : !thumbAnimation}
        onSlideChange={syncMainBeforeChange}
        onSlideChanged={syncMainAfterChange}
        touchTracking={images.length === 1 ? false : !thumbAnimation}
      />
      {images.length > 1 && (
        <div
          className={`thumbs ${images.length < 4 ? "thumbs-thumbnails" : ""}`}
        >
          <AliceCarousel
            activeIndex={thumbIndex}
            autoWidth={images.length < 4}
            disableDotsControls
            disableButtonsControls
            items={thumbs}
            responsive={responsive}
            mouseTracking={false}
            onSlideChanged={syncThumbs}
            touchTracking={!mainAnimation}
          />
          <div className="alice-carousel-btn-prev" onClick={slidePrev}></div>
          <div className="alice-carousel-btn-next" onClick={slideNext}></div>
        </div>
      )}
    </>
  );
};

export default Carousel;
